.jspVerticalBar {
    background: transparent;
    width: 25px;

    @media (max-width: 991px), (max-height: 899px) {
        display: none;
    }

    .jspArrow {
        height: 12px;
    }
}

.jspPane {
    @media (max-width: 991px), (max-height: 899px) {
        margin-left: 0 !important;
    }
}

.jspTrack {
    // background: $blue;
    // padding-left: 12px;
    // padding-right: 12px;
    // background-clip: content-box;
    background: transparent;
    position: relative;

    &:before {
        content: '';
        width: 1px;
        height: calc(100% + 24px);
        top: -12px;
        left: 50%;
        margin-left: -0.5px;
        background-color: $blue;
        position: absolute;
    }
}

.jspDrag {
    width: 11px;
    transform: translateX(-50%);
    border-radius: 50%;
    background: $blue;
    left: 50%;
}

.jspArrow {
    background: transparent;
    position: relative;

    &:before, &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 1px;
        background-color: $blue;
    }

    &.jspArrowUp {
        &:before {
            transform: rotate(45deg);
            left: 12.5px;
            top: 0;
            transform-origin: left;
        }

        &:after {
            transform: rotate(-45deg);
            right: 12.5px;
            top: 0;
            transform-origin: right;
        }
    }

    &.jspArrowDown {
        &:before {
            transform: rotate(-45deg);
            left: 12.5px;
            bottom: 0;
            transform-origin: left;
        }

        &:after {
            transform: rotate(45deg);
            right: 12.5px;
            bottom: 0;
            transform-origin: right;
        }
    }

    &.jspDisabled {
        background: transparent;
    }
}
