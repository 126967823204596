html, body {
    overflow: hidden;

    @media (max-width: 991px), (max-height: 899px) {
        overflow: auto;
    }

    &.menu-open {
        @media (max-width: 991px), (max-height: 899px) {
            overflow-y: hidden;
        }
    }
}


body {
    background-color: $sand;
    font-size: 15px;
    position: relative;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: 14px
    }

    @media (max-width: 991px), (max-height: 899px) {
        font-size: 15px;
    }
}

.pageContainer, #imprintModal .modal-content, #privacyModal .modal-content, #supportModal .modal-content {
    min-height: 100vh;
    max-height: -webkit-fill-available;
    padding-left: 65px;
    padding-right: 65px;
    overflow: hidden;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        padding-left: css-max(30px, css-min(calc(30px + 35 * ((100vh - 899px) / 141)), calc(30px + 35 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 100px;
        max-height: none;
        min-height: 0;
    }

    .page {
        position: relative;
        display: flex;
        min-height: 100vh;
        max-height: -webkit-fill-available;

        @media (max-width: 991px), (max-height: 899px) {
            display: block;
            min-height: 0;
            max-height: none;
        }

        &.multipleContents {
            .contentOuterContainer {
                transition: clip-path 750ms cubic-bezier(0, 0, 0.2, 1);
                pointer-events: none;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 100%;
                clip-path: circle(0px at var(--cursor-x) var(--cursor-y));
                background-color: $sand;
                opacity: 0;

                @media (max-width: 991px), (max-height: 899px) {
                    left: 0;
                    transform: none;
                    clip-path: none;
                    transition: opacity 250ms ease;
                    z-index: 2;
                }

                &:first-child {
                    @media (min-width: 992px) and (min-height: 900px) {
                        position: relative;
                        left: 0;
                        transform: none;
                    }
                }

                &.scope {
                    @media (max-width: 991px), (max-height: 899px) {
                        position: relative;
                        left: 0;
                        transform: none;
                        height: auto;
                    }
                }

                &.top {
                    pointer-events: all;
                    opacity: 1;
                }

                &.active {
                    transition-duration: 1000ms;
                    transition-timing-function: linear;
                    clip-path: circle(200vmax at var(--cursor-x) var(--cursor-y));

                    @media (max-width: 991px), (max-height: 899px) {
                        transition-duration: 250ms;
                        transition-timing-function: ease;
                        clip-path: none;
                    }
                }

                &.noClipPath {
                    clip-path: none !important;
                }
            }
        }
    }
}

.contentOuterContainer {
    display: flex;
    flex-grow: 1;
    max-width: 1790px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &.projectsDetail {
        max-width: none;
        margin-left: -65px;
        background: linear-gradient(to right, $blue 50%, $sand 50%);

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            margin-left: css-max(calc(-30px - 35 * ((100vh - 899px) / 141)), calc(-30px - 35 * ((100vw - 991px) / 209)));
        }

        @media (max-width: 991px), (max-height: 899px) {
            margin-left: 0;
        }

        @media (max-width: 991px) {
            background: none;
        }
    }

    &.wide {
        .contactContainer {
            position: absolute;
            top: 0;
            left: 0;
            height: auto;
            z-index: 3;
        }

        .mainContentContainer {
            .mainContent {
                max-width: none !important;
            }
        }

        .pageTitleRight {
            .mainContentContainer {
                margin-left: 0 !important;
            }
        }

        &.withContactLink {
            .mainContentContainer {
                @media (max-height: 1100px) {
                    padding-top: 170px;
                }

                @media (max-width: 991px), (max-height: 899px) {
                    padding-top: 0;
                }
            }
        }
    }
}

.contactContainer {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: right;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    order: 1;
    text-transform: uppercase;
    font-family: "Duepuntozero Pro", sans-serif;
    position: relative;
    width: 75px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    line-height: 1;
    // margin-left: 20px;

    .contact {
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 40px;
        width: 24px;

        &:after {
            content: '';
            position: absolute;
            width: 75px;
            height: 75px;
            border: 2px solid $purple;
            border-radius: 50%;
            top: 55px;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover {
            color: $blue;
            text-decoration: none;
        }
    }

    @media (max-width: 991px), (max-height: 899px) {
        display: none;
    }
}

.contentContainer {
    min-height: 100vh;
    padding-top: 40px;
    padding-bottom: 100px;
    order: 2;
    flex-grow: 1;
    position: relative;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        padding-bottom: css-max(70px, css-min(calc(70px + 30 * ((100vh - 899px) / 141)), calc(70px + 30 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        min-height: 0;
        padding-bottom: 40px;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 100%;
    }

    @media (max-width: 575px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        display: flex;
        height: 100%;
        position: relative;

        @media (max-width: 991px), (max-height: 899px) {
            display: block;
        }

        .pageTitle {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            display: flex;
            justify-content: center;
            align-self: center;
            position: relative;
            z-index: 1;

            &.blending {
                // mix-blend-mode: difference;
                h2 {
                    .text {
                        color: transparent;
                        background: linear-gradient(
                                        to bottom,
                                        $blue var(--blending-blue-end),
                                        #fff var(--blending-white-start),
                                        #fff var(--white-border-pre-start),
                                        $blue var(--white-border-start),
                                        $blue var(--white-border-end),
                                        #fff var(--white-border-post-end),
                                        #fff var(--blending-white-end),
                                        $blue var(--blending-blue-restart)
                                    );
                        -webkit-background-clip: text;

                        @media (max-width: 991px), (max-height: 899px) {
                            background: none;
                            color: $blue;
                        }
                    }
                }
            }

            @media (max-width: 991px), (max-height: 899px) {
                display: block;
                writing-mode: horizontal-tb;
                transform: none;
                max-width: 1280px;
                margin-top: 0;
                margin-bottom: 0;
                margin-left: auto;
                margin-right: auto;
            }

            @media (max-height: 899px) {
                max-width: none;
            }
        }

        .mainContentContainer {
            flex-grow: 1;
            margin-left: 52px;
            margin-right: 100px;
            align-self: center;

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                margin-left: css-max(30px, css-min(calc(30px + 22 * ((100vh - 899px) / 141)), calc(30px + 22 * ((100vw - 991px) / 209))));
                margin-right: css-max(45px, css-min(calc(45px + 55 * ((100vh - 899px) / 141)), calc(45px + 55 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 991px), (max-height: 899px) {
                margin-left: 0;
                margin-right: 0;
            }

            .mainContent {
                max-width: 1280px;
                margin-left: auto;
                margin-right: auto;
                position: relative;

                @media (max-height: 899px) {
                    max-width: none;
                }
            }
        }
    }

    &.pageTitleRight {
        .pageTitle {
            @media (min-width: 992px), (min-height: 900px) {
                order: 2;
                transform: none;
                margin-right: 66px;
            }

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                margin-right: css-max(30px, css-min(calc(30px + 36 * ((100vh - 899px) / 141)), calc(30px + 36 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 991px), (max-height: 899px) {
                margin-right: auto;
            }
        }

        .mainContentContainer {
            @media (min-width: 992px), (min-height: 900px) {
                order: 1;
                margin-right: 52px;
            }

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                margin-right: css-max(30px, css-min(calc(30px + 22 * ((100vh - 899px) / 141)), calc(30px + 22 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 991px), (max-height: 899px) {
                margin-right: 0;
            }
        }
    }

    &.noPageTitle {
        @media (min-width: 1900px) and (min-height: 900px) {
            max-width: 1840px;
            margin-left: auto;
            margin-right: auto;
        }

        @media (min-width: 992px) and (min-height: 900px) {
            max-width: none;
            margin-left: 30px;
            margin-right: 30px;
        }

        .mainContentContainer {
            @media (min-width: 992px) and (min-height: 900px) {
                margin-right: 52px;
            }

            .mainContent {
                @media (min-width: 992px) and (min-height: 900px) {
                    max-width: none;
                }
            }
        }
    }
}

.metaColumnContainer {
    order: 3;
    width: 65px;
    flex-shrink: 0;
    flex-grow: 0;
    // position: relative;
    // max-height: 100vh;
    min-height: 100vh;
    max-height: -webkit-fill-available;
    position: fixed;
    top: 0;
    right: 0;
    background-color: $sand;
    z-index: 1060;

    @media (max-width: 991px), (max-height: 899px) {
        width: 100%;
        max-height: none;
        min-height: 0;
        right: auto;
        left: 0;
        height: 100vh;
        max-height: -webkit-fill-available;
        pointer-events: none;
        background-color: transparent;
    }

    .metaColumn {
        display: flex;
        writing-mode: vertical-rl;
        width: 100%;
        height: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        border-left: 1px solid $gray-600;
        position: relative;
        z-index: 20;
        background-color: transparent;
        transition: border-color 0ms linear 256ms, background-color 0ms linear 256ms;

        @media (max-width: 991px), (max-height: 899px) {
            writing-mode: horizontal-tb;
            border-left: none;
            border-bottom: 1px solid $gray-600;
            padding-top: 18px;
            padding-bottom: 18px;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: normal;
            height: 100px;
            pointer-events: all;
            background-color: $sand;
        }

        @media (max-width: 575px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .menuToggleContainer {
            writing-mode: horizontal-tb;
            height: 33.33333%;

            @media (max-width: 991px), (max-height: 899px) {
                order: 2;
                margin-left: auto;
                height: auto;
                align-self: center;
            }

            .menuToggle {
                display: block;
                position: relative;

                &:hover, &:focus, &:active {
                    .menuHamburgerContainer {
                        .menuHamburger {
                            .top {
                                transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 300ms linear 0ms;
                            }

                            .middle {
                                transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 300ms linear 100ms;
                            }

                            .bottom {
                                transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 300ms linear 200ms;
                            }

                            .first {
                                .top, .middle, .bottom {
                                    left: 250%;
                                }
                            }

                            .second {
                                .top, .middle, .bottom {
                                    left: 0;
                                }
                            }
                        }
                    }
                }

                .menuHamburgerContainer {
                    position: relative;
                    height: 24px;
                    width: 30px;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    overflow: hidden;

                    @media (max-width: 991px), (max-height: 899px) {
                        transition: transform 300ms linear;
                    }

                    // .menuHamburger {
                    //     top: 50%;
                    //     border-top: 2px solid $black;
                    //
                    //     &, &:before, &:after {
                    //         position: absolute;
                    //         display: block;
                    //         width: 100%;
                    //         transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear;
                    //     }
                    //
                    //     &:before {
                    //         content: '';
                    //         top: -13px;
                    //         transform-origin: 9px 26px;
                    //         border-top: 2px solid $black;
                    //
                    //         @media (max-width: 991px), (max-height: 899px) {
                    //             transform-origin: 6px 18px;
                    //         }
                    //     }
                    //
                    //     &:after {
                    //         content: '';
                    //         bottom: -11px;
                    //         transform-origin: 8px -24px;
                    //         border-bottom: 2px solid $black;
                    //
                    //         @media (max-width: 991px), (max-height: 899px) {
                    //             transform-origin: 5px -16px;
                    //         }
                    //     }
                    // }

                    .menuHamburger {
                        .top, .middle, .bottom {
                            position: absolute;
                            display: block;
                            width: 100%;
                            left: 0;
                        }

                        .top {
                            top: 1px;
                            transform-origin: 9px 24px;
                            border-top: 2px solid $black;
                            transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 0ms linear;

                            @media (max-width: 991px), (max-height: 899px) {
                                transform-origin: 6px 18px;
                            }
                        }

                        .middle {
                            top: 50%;
                             border-top: 2px solid $black;
                             transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 0ms linear;
                        }

                        .bottom {
                            bottom: 0;
                            transform-origin: 9px -22px;
                            border-bottom: 2px solid $black;
                            transition: border-color 0ms linear 256ms, transform 300ms linear, width 300ms linear, left 0ms linear;

                            @media (max-width: 991px), (max-height: 899px) {
                                transform-origin: 7px -16px;
                            }
                        }

                        .first, .second {
                            display: block;
                        }

                        .first {
                            .top, .middle, .bottom {
                                @media (max-width: 991px), (max-height: 899px) {
                                    left: 0 !important;
                                }
                            }
                        }

                        .second {
                            @media (max-width: 991px), (max-height: 899px) {
                                display: none;
                            }

                            .top, .middle, .bottom {
                                left: -250%;
                            }
                        }
                    }
                }
            }
        }

        .logoContainer {
            margin-left: auto;
            margin-right: auto;
            line-height: 1;
            display: block;
            font-size: 0;
            height: 33.33333%;
            text-align: center;

            @media (max-width: 991px), (max-height: 899px) {
                order: 1;
                height: auto;
                align-self: center;
                margin-left: -10px;
                margin-right: 0;
            }

            .logoLink {
                display: none;
                padding: 10px;
                position: relative;

                @media (max-width: 991px), (max-height: 899px) {
                    display: inline-block;
                }
            }

            .logo {
                &.logo-horizontal {
                    width: 200px;
                    height: auto;
                    display: none;

                    &.logo-navi {
                        position: absolute;
                        top: 10px;
                        left: 10px;
                    }

                    @media (max-width: 991px), (max-height: 899px) {
                        display: block;
                        opacity: 1;
                        transition: opacity 0ms linear 256ms;

                        &.logo-navi {
                            opacity: 0;
                        }
                    }
                }

                &.logo-vertical {
                    width: 14px;
                    height: 112px;

                    @media (max-width: 991px), (max-height: 899px) {
                        display: none;
                    }
                }

                path {
                    fill: $blue;
                    transition: fill 0ms linear 256ms;
                }
            }
        }

        .metaNavigationContainer {
            margin-left: auto;
            margin-right: auto;
            height: 33.33333%;

            @media (max-width: 991px), (max-height: 899px) {
                display: none;
                height: auto;
            }
        }
    }

    .metaNavigationContainer {
        text-align: right;
        font-size: 12px;
        line-height: 16px;

        .metaNavigation {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
            padding-top: 0;
            text-transform: uppercase;
            width: 16px;

            .list-inline-item {
                width: 16px;

                &:not(:last-child) {
                    margin-right: 0;
                    border-bottom: 1px solid $black;
                    padding-bottom: 6px;
                    transition: border-color 0ms linear 256ms;
                }

                + .list-inline-item {
                    padding-top: 6px;
                }

                a {
                    color: $black;
                    // padding-left: 10px;
                    // padding-right: 10px;
                    transition: color 0ms linear 256ms;
                    width: 16px;
                    display: block;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                        color: $blue;
                    }
                }
            }
        }
    }

    .mainNavigationContainer {
        width: 425px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $blue;
        z-index: 10;
        // counter-reset: pagination-count;
        padding-left: 30px;
        padding-right: 94px;
        // display: none;
        display: flex;
        pointer-events: none;
        // justify-content: flex-end;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        border-left: 1px solid $sand;
        transform: translateX(100%);
        opacity: 0;
        transition: transform 300ms linear, opacity 0ms linear 300ms;

        @media (max-width: 991px), (max-height: 899px) {
            width: 100%;
            // height: calc(100vh - 63px);
            height: 100vh;
            top: 0;
            right: 0;
            padding-right: 30px;
            padding-top: 100px;
            border-left: none;
            transform: translateY(-100%);
            max-height: -webkit-fill-available;
        }

        @media (max-height: 800px) {
            display: block;
            overflow: auto;
            padding-bottom: 50px;
        }

        @media (max-width: 575px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        > * {
            opacity: 0;
            transition: opacity 0ms linear 256ms;
        }

        .mainNavigation {
            list-style: none;
            margin: 0;
            padding-left: 0;
            // padding-bottom: 300px;
            width: 100%;
            text-align: right;
            font-family: "Duepuntozero Pro", sans-serif;

            @media (max-height: 800px) {
                padding-top: 30px;
                padding-bottom: 30px;
            }

            @media (max-width: 575px) and (max-height: 800px) {
                padding-top: 15px;
                padding-bottom: 15px;
            }

            li {
                a {
                    color: #fff;
                    font-size: 25px;
                    text-transform: uppercase;
                    position: relative;
                    display: inline-block;
                    line-height: 34px;
                    padding-right: 90px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    height: 54px;

                    @media (max-height: 800px) {
                        font-size: 20px;
                        line-height: 26px;
                        height: 42px;
                        padding-right: 80px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    // &:after {
                    //     content: counter(pagination-count);
                    //     counter-increment: pagination-count;
                    //     width: 52px;
                    //     height: 52px;
                    //     line-height: 52px;
                    //     border-radius: 50%;
                    //     border: 2px solid transparent;
                    //     display: block;
                    //     text-align: center;
                    //     position: absolute;
                    //     right: 0;
                    //     top: 0;
                    // }
                    //
                    // &.active:after {
                    //     border-color: #fff;
                    // }

                    .counter {
                        width: 52px;
                        height: 52px;
                        line-height: 52px;
                        border-radius: 50%;
                        border: 2px solid transparent;
                        display: block;
                        text-align: center;
                        position: absolute;
                        right: 0;
                        top: 0;

                        @media (max-height: 800px) {
                            width: 40px;
                            height: 40px;
                            line-height: 38px;
                        }

                        .second {
                            width: 100%;
                        }
                    }

                    &.active .counter {
                        border-color: #fff;
                    }

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                    }
                }
            }
        }

        .loginHint {
            margin-bottom: 230px;
            margin-top: 70px;
            color: #fff;
            text-align: right;

            @media (max-width: 991px), (max-height: 899px) {
                margin-top: 40px;
            }

            @media (max-height: 800px) {
                margin-top: 0;
                margin-bottom: 30px;
            }

            @media (max-width: 575px) and (max-height: 800px) {
                margin-bottom: 20px;
            }

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        .liveChatLink {
            position: absolute;
            left: 75px;
            bottom: 65px;
            width: 180px;
            height: 180px;
            // background-color: $red;
            text-align: center;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            --teaser-link-background-position-x: 50%;
            --teaser-link-background-position-y: 50%;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                pointer-events: none;
                background: url('/fileadmin/images/livechat-bg.jpg') no-repeat;
                background-position: var(--teaser-link-background-position-x) var(--teaser-link-background-position-y);
                transition: background-position linear 5000ms;
                border-radius: 50%;
                background-size: 1060px 750px;
            }

            @media (max-width: 991px), (max-height: 899px) {
                width: 174px;
                height: 174px;
                bottom: 50px;
                left: auto;
                right: 40px;
            }

            @media (max-height: 800px) {
                position: relative;
                left: 0;
                right: auto;
                top: 0;
                bottom: auto;
                float: right;
            }

            @media (max-width: 575px) {
                width: 144px;
                height: 144px;
            }

            &:hover, &:focus, &:active {
                text-decoration: none;
            }

            .liveChatLinkText {
                color: #fff;
                font-size: 32px;
                line-height: 1.1;
                text-align: center;
                padding-left: 20px;
                padding-right: 20px;
                font-weight: bold;
                text-transform: uppercase;
                font-family: "Duepuntozero Pro", sans-serif;
                position: relative;

                @media (max-width: 991px), (max-height: 899px) {
                    font-size: 25px;
                }

                @media (max-width: 575px) {
                    font-size: 20px;
                }
            }
        }

        .metaNavigationContainer {
            display: none;
            position: absolute;
            left: 30px;
            bottom: 30px;
            writing-mode: vertical-rl;

            @media (max-width: 991px), (max-height: 899px) {
                display: block;
            }

            @media (max-width: 575px) {
                left: 15px;
            }

            .metaNavigation {

                .list-inline-item {
                    &:not(:last-child) {
                        border-color: #fff;
                    }

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    &.menu-active {
        .metaColumn {
            background-color: $blue;
            border-color: #fff;
            transition: border-color 0ms linear 44ms, background-color 0ms linear 44ms;

            .menuToggleContainer {
                .menuToggle {
                    // .menuHamburgerContainer {
                    //     @media (max-width: 991px), (max-height: 899px) {
                    //         transform: rotate3d(0, 0, 1, -90deg);
                    //         width: 25px;
                    //     }
                    //
                    //     .menuHamburger {
                    //         &, &:before, &:after {
                    //             transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear;
                    //             border-color: #fff;
                    //         }
                    //
                    //         &:before {
                    //             transform: rotate3d(0, 0, 1, 45deg);
                    //             width: 15px;
                    //         }
                    //
                    //         &:after {
                    //             transform: rotate3d(0, 0, 1, -45deg);
                    //             width: 15px;
                    //         }
                    //     }
                    // }

                    &:hover, &:focus, &:active {
                        .menuHamburgerContainer {
                            .top {
                                transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 300ms linear;
                            }

                            .middle {
                                transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 300ms linear;
                            }

                            .bottom {
                                transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 300ms linear;
                            }
                        }
                    }

                    .menuHamburgerContainer {
                        @media (max-width: 991px), (max-height: 899px) {
                            transform: rotate3d(0, 0, 1, -90deg);
                        }

                        .top, .middle, .bottom {
                            border-color: #fff;
                        }

                        .top {
                            width: 15px;
                            transform: rotate3d(0, 0, 1, 45deg);
                            transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 0ms linear;
                        }

                        .middle {
                            transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 0ms linear;

                            @media (max-width: 991px), (max-height: 899px) {
                                width: 25px;
                            }
                        }

                        .bottom {
                            width: 15px;
                            transform: rotate3d(0, 0, 1, -45deg);
                            transition: border-color 0ms linear 44ms, transform 300ms linear, width 300ms linear, left 0ms linear;
                        }
                    }
                }
            }

            .logoContainer .logo {

                &.logo-horizontal {
                    @media (max-width: 991px), (max-height: 899px) {
                        opacity: 0;
                        transition: fill 0ms linear 44ms;

                        &.logo-navi {
                            opacity: 1;
                        }
                    }
                }
                path {
                    fill: #fff;
                    transition: fill 0ms linear 44ms;
                }
            }

            .metaNavigationContainer .metaNavigation .list-inline-item {
                &:not(:last-child) {
                    border-color: #fff;
                    transition: border-color 0ms linear 44ms;
                }

                a {
                    color: #fff;
                    transition: color 0ms linear 44ms;
                }
            }
        }

        .mainNavigationContainer {
            // display: flex;
            pointer-events: all;
            transform: translateX(0);
            opacity: 1;
            transition: transform 300ms linear, opacity 0ms linear;
            // transition: clip-path 1000ms linear, opacity 1ms linear;

            @media (max-width: 991px), (max-height: 899px) {
                transform: translateY(0);
            }

            > * {
                opacity: 1;
                transition: opacity 0ms linear 44ms;
            }
        }
    }
}

.sectionSwiper {
    .metaNavigationContainer {
        display: none;
        font-size: 13px;
        line-height: 17px;
        margin-left: 30px;
        margin-right: 30px;
        margin-bottom: 30px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid $gray-600;
        border-bottom: 1px solid $gray-600;

        @media (max-width: 991px), (max-height: 899px) {
            display: block;
        }

        @media (max-width: 575px) {
            margin-left: 15px;
            margin-right: 15px;
        }

        .metaNavigation {
            margin-bottom: 0;
            text-transform: uppercase;

            .list-inline-item {
                &:not(:last-child) {
                    margin-right: 0;
                    border-right: 1px solid $blue;
                    padding-right: 6px;
                }

                + .list-inline-item {
                    padding-left: 6px;
                }

                a {
                    color: $blue;

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.project-detail-active {
    .swiper-pagination-sections {
        display: none;

        @media (max-width: 991px), (max-height: 899px) {
            display: flex;
        }
    }
}

#home-slide {
    .contentContainer .content .mainContentContainer .mainContent {
        @media (min-width: 992px) and (min-height: 900px) {
            min-height: 75vh;
            display: flex;
            flex-direction: column;
        }

        .teaserLinkOuterContainer {
            @media (min-width: 992px) and (min-height: 900px) {
                flex-grow: 1;
            }
        }
    }
}

#home-slide {
    .logoContainer {
        position: absolute;
        top: 40px;
        left: 66px;
        width: 300px;

        @media (max-width: 991px), (max-height: 899px) {
            display: none;
        }
    }

    &.pageContainer {
        overflow: visible;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: calc(var(--signet-space) - 780px - 10px);
            width: 780px;
            height: calc(100% - 285px);
            margin-bottom: 100px;
            margin-top: 185px;
            pointer-events: none;
            background: url("/fileadmin/images/logo-sign.svg") no-repeat bottom right;
            background-size: auto 100%;

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                // display: none;
                left: -20vw;
                opacity: 0.5;
            }

            @media (max-width: 991px), (max-height: 899px) {
                width: 70vw;
                height: calc(100% - 150px);
                margin-bottom: 0;
                margin-top: 150px;
                background-position: bottom left;
                background-size: contain;
            }

            @media (max-width: 575px) {
                width: 100vw;
                left: -40vw;
            }
        }
    }

    .contactContainer {
        display: none;
    }
}

#contact-slide .contactContainer {
    visibility: hidden;
}

#swifttagcontainero31f9zyyez {
    display: none;
}
