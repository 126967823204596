.infoOverlayContainer {
    position: relative;

    .infoOverlay {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, 0);
        margin-bottom: 10px;
        padding: 20px;
        background-color: #fff;
        text-align: center;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);

        &:after {
            display: block;
            content: '';
            width: 0;
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -5px;
            border-top: 10px solid #fff;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
        }
    }
}
