h4 + .hr + .multiColumnContainer {
    margin-top: 45px;

    @media (max-width: 767px) {
        margin-top: 20px;
    }
}

.leadContainer + .multiColumnContainer {
    margin-top: 45px;

    @media (max-width: 767px) {
        margin-top: 20px;
    }
}


.multiColumnContainer {
    h3, .h3 {
        margin-bottom: 40px;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        br {
            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    h4, .h4 {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 40px;

        @media (max-width: 991px) {
            margin-bottom: 24px;
            margin-top: 40px;
        }

        br {
            @media (max-width: 991px) {
                display: none;
            }
        }
    }
}
