// We cannot use css min() and max() normally, because it collides with sass min() and max()
// Alternatively, we could write MIN() and MAX() for css functions, as css is case insensitive.

@function css-function( $function, $values... ) {
    @return
        $function
        + unquote( '(' )
        + $values
        + unquote( ')' )
    ;
}

@function css-min( $values... ) {
    @return css-function( min, $values );
}

@function css-max( $values... ) {
    @return css-function( max, $values );
}

@function css-clamp( $values... ) {
    @return css-function( clamp, $values );
}
