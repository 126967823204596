.strengthsOuterContainer {
    display: flex;
    margin-top: 90px;

    @media (max-width: 1600px), (max-height: 1040px) {
        display: block;
        margin-top: 45px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            margin-top: css-max(30px, css-min(calc(30px + 15 * ((100vh - 899px) / 141)), calc(30px + 15 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }

    h3 {
        margin-left: 45px;
        margin-right: 45px;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        text-align: right;
        line-height: 1;

        @media (max-width: 1600px), (max-height: 1040px) {
            writing-mode: horizontal-tb;
            text-align: left;
            transform: none;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
        }

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            margin-bottom: css-max(24px, css-min(calc(24px + 6 * ((100vh - 899px) / 141)), calc(24px + 6 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            margin-bottom: 24px;
        }

        br {
            @media (max-width: 1600px), (max-height: 1040px) {
                display: none;
            }
        }
    }

    .strengthsContainer {

        .strength {
            padding-left: 180px;
            position: relative;

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                padding-left: css-max(150px, css-min(calc(150px + 30 * ((100vh - 899px) / 141)), calc(150px + 30 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 991px) {
                padding-left: 150px;
            }

            @media (max-width: 575px) {
                padding-left: 108px;
            }

            @media (max-width: 420px) {
                padding-left: 0;
            }

            + .hr {
                margin-bottom: 24px;
            }

            .index {
                font-family: "hothouse", sans-serif;
                font-weight: bold;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 72px;
                line-height: 84px;

                @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                    font-size: css-max(56px, css-min(calc(56px + 16 * ((100vh - 899px) / 141)), calc(56px + 16 * ((100vw - 991px) / 209))));
                    line-height: css-max(72px, css-min(calc(72px + 12 * ((100vh - 899px) / 141)), calc(72px + 12 * ((100vw - 991px) / 209))));
                }

                @media (max-width: 991px) {
                    font-size: 56px;
                    line-height: 72px;
                }

                @media (max-width: 575px) {
                    font-size: 44px;
                    line-height: 56px;
                }

                @media (max-width: 420px) {
                    font-size: 15px;
                    line-height: 27px;
                    font-weight: 600;
                }
            }

            .strengthTitle {
                color: $black;
                font-weight: 400;
                // text-transform: none;
                padding-bottom: 0;
                margin-bottom: 24px;

                @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                    margin-bottom: css-max(15px, css-min(calc(15px + 9 * ((100vh - 899px) / 141)), calc(15px + 9 * ((100vw - 991px) / 209))));
                }

                @media (max-width: 991px) {
                    margin-bottom: 15px;
                }

                @media (max-width: 420px) {
                    margin-left: 40px;
                }
            }
        }
    }
}
