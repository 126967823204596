@font-face {
    font-family: 'iconfont';
    src:
        url('../fonts/iconfont/iconfont.ttf?iy57hh') format('truetype'),
        url('../fonts/iconfont/iconfont.woff?iy57hh') format('woff'),
        url('../fonts/iconfont/iconfont.svg?iy57hh#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"], %iconfont {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-external:before, %icon-external {
  content: "\e900";
}
.icon-arrow-right:before, %icon-arrow-right {
  content: "\e901";
}
.icon-arrow-left:before, %icon-arrow-left {
  content: "\e902";
}
.icon-arrow-top:before, %icon-arrow-top {
  content: "\e908";
}
.icon-arrow-down:before, %icon-arrow-down {
  content: "\e909";
}
.icon-chevron-right:before, %icon-chevron-right {
  content: "\e903";
}
.icon-chevron-right-narrow:before, %icon-chevron-right-narrow {
  content: "\e904";
}
.icon-chevron-right-narrow-2:before, %icon-chevron-right-narrow-2 {
  content: "\e90f";
}
.icon-chevron-bottom-wide:before, %icon-chevron-bottom-wide {
  content: "\e905";
}
.icon-chevron-bottom-narrow:before, %icon-chevron-bottom-narrow {
  content: "\e90d";
}
.icon-twitter-square:before, %icon-twitter-square {
  content: "\e906";
}
.icon-facebook-square:before, %icon-facebook-square {
  content: "\e907";
}
.icon-instagram-square:before, %icon-instagram-square {
  content: "\e910";
}
.icon-check:before, %icon-check {
  content: "\e90a";
}
.icon-envelope-o:before, %icon-envelope-o {
  content: "\e90b";
}
.icon-comments-o:before, %icon-comments-o {
  content: "\e90c";
}
.icon-one-finger:before, %icon-one-finger {
  content: "\e90e";
}
