.teaserLinkOuterContainer {
    min-height: 214px;
    position: relative;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        min-height: css-max(174px, css-min(calc(174px + 40 * ((100vh - 899px) / 141)), calc(174px + 40 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        min-height: 174px;
    }

    @media (max-width: 575px) {
        min-height: 144px;
    }

    @media (max-width: 420px) {
        min-height: 120px;
    }

    .teaserLinkContainer {
        position: absolute;
        width: 214px;
        height: 214px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        --teaser-link-background-position-x: 50%;
        --teaser-link-background-position-y: 50%;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: url('/fileadmin/images/teaser-link-bg.jpg') no-repeat;
            background-position: var(--teaser-link-background-position-x) var(--teaser-link-background-position-y);
            transition: background-position linear 5000ms;
            border-radius: 50%;
            background-size: 1060px 750px;
        }

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            width: css-max(174px, css-min(calc(174px + 40 * ((100vh - 899px) / 141)), calc(174px + 40 * ((100vw - 991px) / 209))));
            height: css-max(174px, css-min(calc(174px + 40 * ((100vh - 899px) / 141)), calc(174px + 40 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            width: 174px;
            height: 174px;
        }

        @media (max-width: 575px) {
            width: 144px;
            height: 144px;
        }

        @media (max-width: 420px) {
            width: 120px;
            height: 120px;
        }

        &:hover, &:focus, &:active {
            text-decoration: none;

            .teaserLinkAnimationOuterWrapper {
                transform: rotate(-13.5deg);
            }

            .teaserLinkAnimationWrapper {
                animation-play-state: paused;
            }

            .teaserLinkCircle {
                animation-play-state: running;
            }
        }

        .teaserLinkAnimationOuterWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 750ms ease-out;
            overflow: hidden;
        }

        .teaserLinkAnimationWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            animation: rotate 20s infinite linear;
        }

        .teaserLinkCircle {
            position: absolute;
            // width: 100%;
            // height: 100%;
            width: 110%;
            height: 110%;
            left: -5%;
            top: -5%;
            animation: rotate 20s infinite linear reverse;
            animation-play-state: paused;


            text {
                font-size: 20px;
                fill: #fff;
                font-family: "Duepuntozero Pro", sans-serif;
            }
        }

        .teaserLinkText {
            font-family: "Duepuntozero Pro", sans-serif;
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 25px;
            line-height: 1.1;
            text-align: center;
            padding-left: 20px;
            padding-right: calc(15px + 0.95em);
            // padding-right: 55px;
            position: relative;

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                font-size: css-max(20px, css-min(calc(20px + 5 * ((100vh - 899px) / 141)), calc(20px + 5 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 991px) {
                font-size: 20px;
            }

            @media (max-width: 575px) {
                font-size: 16px;
                line-height: 22px;
            }

            @media (max-width: 420px) {
                font-size: 14px;
                line-height: 18px;
            }

            &:after {
                @extend %iconfont;
                @extend %icon-chevron-right-narrow-2;
                display: block;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.8em;
            }
        }
    }

    &.top {
        @media (max-width: 1600px) {
            min-height: 0;
        }

        .teaserLinkOuter {
            @media (max-width: 1600px) {
                background-color: $sand;
                padding-left: 30px;
                padding-bottom: 24px;
                position: relative;
                z-index: 5;
            }

            @media (max-width: 575px) {
                padding-left: 15px;
                padding-bottom: 15px;
            }

            .teaserLinkContainer {
                top: 0;

                @media (max-width: 1600px) {
                    position: relative;
                }
            }
        }

        &.right {
            @media (max-width: 1600px) {
                padding-right: 0;
            }

            .teaserLinkOuter {
                @media (max-width: 1600px) {
                    float: right;
                    padding-left: 30px;
                }

                @media (max-width: 575px) {
                    padding-left: 15px;
                }
            }
        }

        &.left {
            .teaserLinkOuter {
                @media (max-width: 1600px) {
                    float: left;
                    padding-right: 30px;
                }

                @media (max-width: 575px) {
                    padding-right: 15px;
                }
            }
        }
    }

    &.right {
        padding-right: 300px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            padding-right: css-max(225px, css-min(calc(225px + 75 * ((100vh - 899px) / 141)), calc(225px + 75 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            padding-right: 225px;
        }

        .teaserLinkContainer {
            right: 0;
        }
    }

    &.bottom {
        .teaserLinkContainer {
            bottom: 0;
        }
    }

    &.left {
        padding-left: 300px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            padding-left: css-max(225px, css-min(calc(225px + 75 * ((100vh - 899px) / 141)), calc(225px + 75 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            padding-left: 225px;
        }

        .teaserLinkContainer {
            left: 0;
        }
    }

    &.inContent {
        padding-left: 0;
        padding-right: 0;
        min-height: 0;

        .teaserLinkContainer {
            position: relative;
        }
    }

    .leadContainer:last-child {
        border-bottom: 0;
        margin-bottom: 0;
    }

    h4 {
        br {
            @media (max-width: 1600px) {
                display: none;
            }
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }

    // + .hr {
    //     margin-bottom: 28px;
    //
    //     @media (max-width: 991px) {
    //         margin-bottom: 20px;
    //     }
    // }
}

h4 + .teaserLinkOuterContainer {
    margin-top: 45px;
}

.teaserLinkOuterContainer h4 + .hr + p, .teaserLinkOuterContainer h4 + .hr + .teaserLinkOuter + p {
    margin-top: 24px;
}

#home-slide {
    .teaserLinkOuterContainer {
        &:first-child {
            .teaserLinkOuter {
                display: none;

                @media (max-width: 1200px) {
                    display: block;
                }
            }
        }

        &:last-child {
            .teaserLinkOuter {
                @media (max-width: 1200px) {
                    display: none;
                }
            }
        }

        &.left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            @media (max-width: 1200px) {
                padding-left: 0;
                // padding-right: css-max(225px, css-min(calc(225px + 75 * ((100vh - 899px) / 141)), calc(225px + 75 * ((100vw - 991px) / 209))));
            }

            @media (max-width: 767px) {
                display: block;
            }

            .teaserLinkOuter {
                @media (max-width: 767px) {
                    // display: none;
                    float: right;
                }

                .teaserLinkContainer {
                    bottom: 50%;
                    margin-bottom: -80px;
                    left: -82px;

                    @media (max-width: 1200px) {
                        left: auto;
                        right: 0;
                        background-color: $sand;
                        box-sizing: content-box;
                        bottom: 0;
                        margin-bottom: 0;
                    }

                    @media (max-width: 767px) {
                        position: relative;
                        margin-bottom: 24px;
                    }

                    @media (max-width: 575px) {
                        margin-bottom: 15px;
                    }
                }
            }

            &.clearfix {
                &:after {
                    display: none;
                }
            }
        }

        // &.bottom.right {
        //     @media (max-width: 767px) {
        //         padding-right: 0;
        //         min-height: 0;
        //     }
        //
        //     .teaserLinkContainer {
        //         bottom: auto;
        //         top: 50%;
        //         margin-top: -107px;
        //
        //         @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        //             margin-top: css-max(calc(-87px - 20 * ((100vh - 899px) / 141)), calc(-87px - 20 * ((100vw - 991px) / 209)));
        //         }
        //
        //         @media (max-width: 991px) {
        //             top: 10px;
        //             margin-top: 0;
        //         }
        //
        //         @media (max-width: 767px) {
        //             display: none;
        //         }
        //     }
        // }

        &.inContent {
            padding-top: 45px;
            display: none;

            @media (max-width: 767px) {
                // display: block;
            }

            .teaserLinkContainer {
                margin-left: auto;
            }
        }
    }

    &.swiper-slide-active, &:not(.animation-init) {
        .rollInFromLeft.animation {
            @media (max-width: 1200px) {
                transform: translateX(25%) rotate(45deg);
            }
        }
    }

    &.swiper-slide-active.animation-ready {
        .rollInFromLeft.animation {
            @media (max-width: 1200px) {
                transform: translateX(0%) rotate(0deg);
            }
        }
    }
}

#projects-slide {
    .teaserLinkOuterContainer {
        &.right {
            @media (max-width: 1400px) {
                padding-right: 0;
            }



            .teaserLinkContainer {
                @media (max-width: 1200px) {
                    right: -30px;
                }

                @media (max-width: 991px) {
                    right: 0;
                }

                @media (max-width: 700px) {
                    position: relative;
                    margin-left: auto;
                }
            }
        }
    }
}

#content-jobs-project-management,
#content-jobs-web-developer,
#content-jobs-marketing,
#content-jobs-it-specialist,
#content-jobs-assistant,
#content-jobs-project-management-webercloud-china {
    .teaserLinkOuterContainer {
        &.right {
            .teaserLinkContainer {
                right: 100px;

                @media (max-width: 1200px) {
                    right: 50px;
                }

                @media (max-width: 991px) {
                    margin-bottom: 0;
                }

                @media (max-width: 767px) {
                    right: 0;
                }
            }
        }
    }
}

#contact-slide {
    .teaserLinkOuterContainer {
        @media (max-width: 767px) {
            // display: flex;
            // flex-direction: column;
        }

        .teaserLinkContainer {
            @media (max-width: 991px) {
                position: relative;
            }
        }
    }

    #content-contact {
        .teaserLinkOuterContainer .teaserLinkContainer .teaserLinkText:after {
            right: 13px;
        }
    }

    #content-ceo {
        .teaserLinkOuterContainer {
            order: 2;
            margin-top: 30px;

            @media (max-width: 1200px) {
                min-height: 0;
                margin-top: 0;
            }

            .teaserLinkOuter {
                @media (max-width: 1200px) {
                    float: right;
                    padding-left: 30px;
                    padding-bottom: 24px;
                }

                @media (max-width: 575px) {
                    padding-left: 15px;
                    padding-bottom: 15px;
                }

                .teaserLinkContainer {
                    @media (max-width: 1200px) {
                        position: relative;
                    }

                    @media (max-width: 991px) {
                        margin-bottom: 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

#about-slide {
    .teaserLinkOuterContainer {
        // @media (max-width: 767px) {
        //     display: flex;
        //     flex-direction: column;
        // }

        &.top {
            .teaserLinkOuter {
                @media (max-width: 1600px) {
                    padding-bottom: 0;
                }

                @media (max-width: 767px) {
                    top: 0;
                    padding-bottom: 24px;
                }

                @media (max-width: 567px) {
                    top: 0;
                    padding-bottom: 15px;
                }
                //
                // @media (max-width: 767px) {
                //     float: none;
                //     order: 3;
                // }
            }

            .teaserLinkContainer {
                top: -20px;

                @media (max-width: 767px) {
                    top: 0;
                    margin-top: 12px;
                }
            }
        }
    }
}

#expertise-slide {
    .teaserLinkOuterContainer {
        &.bottom.right {
            position: static;
            min-height: 0;
            padding: 0;

            .teaserLinkContainer {
                bottom: -50px;
                right: 70%;

                @media (max-width: 1300px) {
                    right: 0;
                }

                @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                    bottom: css-max(calc(-20px - 30 * ((100vh - 899px) / 141)), calc(-20px - 30 * ((100vw - 991px) / 209)));
                }

                @media (max-width: 991px) {
                    bottom: 0;
                    right: auto;
                    left: 15px;
                }
            }
        }

        &.top.right {
            padding-right: 0;
        }
    }

    &.swiper-slide-active, &:not(.animation-init) {
        .rollInFromLeft.animation {
            @media (max-width: 1300px) {
                transform: translateX(25%) rotate(45deg);
            }
        }
    }

    &.swiper-slide-active.animation-ready {
        .rollInFromLeft.animation {
            @media (max-width: 1300px) {
                transform: translateX(0%) rotate(0deg);
            }
        }
    }
}

#jobs-slide {
    .teaserLinkOuterContainer {
        &.right {
            @media (max-width: 1200px) {
                padding-right: 0;
            }
        }
    }

    #content-jobs-overview {
        .teaserLinkOuterContainer {
            &.right {
                .teaserLinkOuter {
                    @media (max-width: 1200px) {
                        float: right;
                        margin-top: 24px;
                        margin-bottom: 24px;
                        margin-left: 30px;
                    }

                    @media (max-width: 575px) {
                        margin-left: 15px;
                        margin-bottom: 24px;
                    }

                    .teaserLinkContainer  {
                        @media (max-width: 1200px) {
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}
