.projectsContainer {
    margin-top: 45px;

    .project {
        margin-bottom: 24px;

        a {
            display: flex;
            align-items: center;
            position: relative;

            &:hover, &:focus, &:active {
                text-decoration: none;

                .projectTitle {
                    color: $blue;
                }
            }
        }

        .projectTitle {
            font-family: "Duepuntozero Pro", sans-serif;
            font-weight: 700;
            font-size: 72px;
            line-height: 1.25em;
            color: $black;

            @media (max-width: 1750px) {
                font-size: calc(44px + 28 * ((100vw - 1400px) / 350));
            }

            @media (max-width: 1400px) {
                font-size: calc(32px + 12 * ((100vw - 1200px) / 200));
            }

            @media (max-width: 1200px) {
                font-size: 32px;
            }

            @media (max-width: 991px) {
                font-size: 38px;
            }

            @media (max-width: 767px) {
                color: $blue;
                margin-bottom: 7px;
            }

            @media (max-width: 575px) {
                font-size: 30px;
            }

            // @media (max-width: 1800px) {
            //     font-size: calc(60px + 12 * ((100vw - 1600px) / 200));
            // }
            //
            // @media (max-width: 1600px) {
            //     font-size: calc(34px + 26 * ((100vw - 1200px) / 400));
            // }
            //
            // @media (max-width: 1200px) {
            //     font-size: calc(32px + 4 * ((100vw - 991px) / 209));
            // }
            //
            // // @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            // @media (max-width: 1200px) and (min-height: 900px) and (max-height: 1040px) {
            //     font-size: css-max(32px, css-min(calc(32px + 2 * ((100vh - 899px) / 141)), calc(32px + 2 * ((100vw - 991px) / 209))));
            // }
            //
            // @media (max-width: 991px) {
            //     font-size: calc(36px + 14 * ((100vw - 767px) / 224));
            // }
            //
            // @media (max-width: 767px) {
            //     font-size: calc(34px + 12 * ((100vw - 575px) / 192));
            //     color: $blue;
            //     margin-bottom: 7px;
            // }
            //
            // @media (max-width: 575px) {
            //     font-size: calc(28px + 14 * ((100vw - 480px) / 175));
            // }
            //
            // @media (max-width: 480px) {
            //     font-size: 22px;
            // }
        }

        .content-link {
            padding-left: 30px;
            height: 60px;

             @media (max-width: 767px) {
                 display: none;
             }
        }

        .marqueeContainer {
            color: $black;

            .marquee {
                margin-bottom: 0;
                line-height: 1.5;
            }
        }
    }
}

.projectsDetail {
    .projectDetailContentContainer {
        position: relative;
        min-height: 100vh;
        overflow: hidden;
        max-width: 1790px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 65px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            padding-left: css-max(30px, css-min(calc(30px + 35 * ((100vh - 899px) / 141)), calc(30px + 35 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        .projectDetailContent {
            display: flex;
            height: 100%;
            position: relative;
            margin-left: 0;
            margin-right: 0;

            .projectImagesColumnContainer {
                height: 100%;
                padding-top: 65px;
                padding-bottom: 115px;
                background-color: $blue;
                padding-left: 0;
                padding-right: 0;
                flex: 0 0 33.33333%;
                max-width: 33.33333%;

                @media (max-width: 1600px) {
                    flex: 0 0 41.66667%;
                    max-width: 41.66667%;
                }

                @media (max-width: 991px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding: 30px;
                    height: auto;
                }

                @media (max-width: 575px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .projectImagesColumn {
                    padding-left: 45px;
                    padding-right: 45px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background-color: $sand;
                    position: relative;
                    // avoid anti aliasing
                    box-shadow: 1px 0 0 $sand;

                    @media (max-width: 1200px) {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    @media (max-width: 991px) {
                        height: auto;
                        padding: 20px 30px 90px 30px;
                        box-shadow: none;
                    }

                    .projectImagesColumnInner {
                        width: 100%;

                        .projectImagesSlider {
                            .swiper-slide-active {
                                z-index: 1;
                            }

                            a[data-toggle="lightbox"] {
                                display: block;
                            }
                        }

                        .projectImagesFunctionContainer {
                            position: absolute;
                            bottom: 10px;
                            right: 0;
                            display: flex;

                            .swiper-pagination-projects {
                                align-self: flex-end;
                                // margin-right: 30px;
                            }

                            .zoomLinkContainer {
                                writing-mode: vertical-rl;
                                transform: rotate(180deg);
                                text-align: right;
                                text-transform: uppercase;
                                font-family: "Duepuntozero Pro", sans-serif;
                                position: relative;
                                width: 75px;
                                display: flex;
                                justify-content: flex-end;
                                font-size: 14px;

                                .zoomLink {
                                    position: relative;
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                    padding-bottom: 40px;

                                    &:after {
                                        content: '';
                                        position: absolute;
                                        width: 75px;
                                        height: 75px;
                                        border: 1px solid #bcbcf1;
                                        border-radius: 50%;
                                        top: 12px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                }
                            }
                        }
                    }

                    .websiteLinkContainer {
                        position: absolute;
                        bottom: 30px;
                        left: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        // .external-link {
                        //     @media (max-width: 480px) {
                        //         font-size: 10px;
                        //         padding-right: 30px;
                        //     }
                        //
                        //     &:after {
                        //         @media (max-width: 480px) {
                        //             font-size: 20px;
                        //         }
                        //     }
                        // }
                    }
                }

                .projectsBackLink {
                    position: absolute;
                    bottom: 25px;
                    left: 0;
                    color: #fff;

                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }

            .projectTextColumnContainer {
                height: 100%;
                padding-top: 40px;
                padding-bottom: 40px;
                padding-left: 45px;
                padding-right: 45px;
                display: flex;
                align-items: center;
                background-color: $sand;
                flex: 0 0 66.66667%;
                max-width: 66.66667%;

                @media (max-width: 1600px) {
                    flex: 0 0 58.33333%;
                    max-width: 58.33333%;
                }

                @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                    padding: 40px 30px;
                }

                @media (max-width: 991px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 40px;
                    height: auto;
                }

                @media (max-width: 575px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .projectTextColumn {
                    width: 100%;

                    .projectTitleContainer {
                        padding-top: 0;
                        margin-bottom: 50px;

                        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                            margin-bottom: 30px;
                        }

                        h2 {
                            padding-bottom: 38px;
                            border-bottom: 1px solid $gray-600;
                            margin-bottom: 0;

                            @media (max-width: 1920px) {
                                font-size: calc(84px + 25 * ((100vw - 1600px) / 320));
                                line-height: calc(96px + 40 * ((100vw - 1600px) / 320));
                            }

                            @media (max-width: 1600px) {
                                font-size: calc(54px + 26 * ((100vw - 1200px) / 400));
                                line-height: calc(62px + 34 * ((100vw - 1200px) / 400));
                            }

                            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                                font-size: css-max(40px, css-min(calc(40px + 14 * ((100vh - 899px) / 141)), calc(40px + 14 * ((100vw - 991px) / 209))));
                                line-height: css-max(46px, css-min(calc(46px + 16 * ((100vh - 899px) / 141)), calc(46px + 16 * ((100vw - 991px) / 209))));
                                padding-bottom: 30px;
                            }

                            @media (max-width: 991px) {
                                font-size: 40px;
                                line-height: 46px;
                            }

                            @media (max-width: 575px) {
                                font-size: 32px;
                                line-height: 40px;
                            }
                        }

                        .websiteLinkContainer {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }
                    }

                    .projectOverviewContainer {
                        display: flex;

                        p {
                            text-align: center;
                            max-width: 512px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    .projectDetailContainer {
                        margin-top: 50px;
                        text-align: center;

                        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                            margin-top: 30px;
                        }

                        @media (max-width: 991px) {
                            text-align: left;
                        }

                        // .swappableTextWrapper {
                        //     overflow: hidden;
                        //
                        //     .first {
                        //         span {
                        //             display: inline-block;
                        //             transition: transform 250ms cubic-bezier(0.65, 0, 0.21, 1.47);
                        //
                        //             @for $i from 0 through 20 {
                        //                 &:nth-of-type(#{$i}) {
                        //                     transition-delay: 0.03s * $i + 0.05;
                        //                 }
                        //             }
                        //         }
                        //     }
                        //
                        //     .second {
                        //         span {
                        //             display: inline-block;
                        //             transition: transform 250ms cubic-bezier(0.65, 0, 0.21, 1.47);
                        //
                        //             @for $i from 0 through 20 {
                        //                 &:nth-of-type(#{$i}) {
                        //                     transition-delay: 0.02s * $i;
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }
                        //
                        // .swappableContentWrapper {
                        //     overflow: hidden;
                        //
                        //     .first, .second {
                        //         transition: transform 500ms cubic-bezier(0.65, 0, 0.21, 1.47);
                        //     }
                        // }

                        h4 {
                            font-size: 24px;
                            line-height: 28px;

                            .swappableTextWrapper {
                                height: 17px;
                            }
                        }

                        .hr {
                            margin-bottom: 50px;
                            margin-top: 50px;

                            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                                margin-top: 30px;
                                margin-bottom: 30px;
                            }
                        }

                        .blockquote-footer {
                            position: relative;

                            @media (max-width: 991px) {
                                padding-left: 20px;
                            }

                            &:before {
                                @media (max-width: 991px) {
                                    position: absolute;
                                    left: 0;
                                }
                            }
                        }

                        // .content-link {
                        //     display: inline-block;
                        //     margin-top: 50px;
                        //     padding-left: 45px;
                        //
                        //     &:before {
                        //         transition: right 500ms cubic-bezier(0.65, 0, 0.21, 1.47), transform 250ms linear;
                        //     }
                        //
                        //     &:after {
                        //         transition: right 500ms cubic-bezier(0.65, 0, 0.21, 1.47);
                        //     }
                        //
                        //     .swappableTextWrapper {
                        //         height: 20px;
                        //     }
                        // }

                        // &.swapped {
                        //     .content-link {
                        //         &:before {
                        //             right: calc(100% - 30px);
                        //             transform: rotate(180deg);
                        //         }
                        //
                        //         &:after {
                        //             right: calc(100% - 60px);
                        //         }
                        //     }
                        //
                        //     .swappableTextWrapper {
                        //         .first {
                        //             span {
                        //                 transform: translateY(-100%);
                        //
                        //                 @for $i from 0 through 20 {
                        //                     &:nth-of-type(#{$i}) {
                        //                         transition-delay: 0.02s * $i;
                        //                     }
                        //                 }
                        //             }
                        //         }
                        //
                        //         .second {
                        //             span {
                        //                 transform: translateY(-100%);
                        //
                        //                 @for $i from 0 through 20 {
                        //                     &:nth-of-type(#{$i}) {
                        //                         transition-delay: 0.03s * $i + 0.05;
                        //                     }
                        //                 }
                        //             }
                        //         }
                        //     }
                        //
                        //     .swappableContentWrapper {
                        //         .first, .second {
                        //             transform: translateY(-100%);
                        //         }
                        //     }
                        // }
                    }

                    .projectsBackLink {
                        display: none;

                        @media (max-width: 991px) {
                            display: block;
                            margin-top: 50px;
                        }
                    }

                    .customerQuoteContainer {
                        padding-top: 20px;
                        border-bottom: 1px solid $gray-600;

                        h4 {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}
