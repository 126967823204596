.addressContainer {
    text-align: right;
    display: flex;
    flex-direction: column;
    order: 2;

    @media (max-width: 767px) {
        text-align: left;
        order: 1;
    }

    p {
        // margin-bottom: 0;
        font-family: "Duepuntozero Pro", sans-serif;
        text-transform: none;
        line-height: 1.2em;

        @media (max-width: 575px) {
            font-size: 20px;
            line-height: 26px;
        }

        .big {
            line-height: 1.2em;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .hr {
        margin-top: 10px;
        margin-bottom: 10px;

        @media (max-width: 991px) {
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}

.contactOptions {
    order: 1;

    @media (max-width: 767px) {
        order: 2;
        margin-top: 40px;
        display: flex;
    }

    @media (max-width: 400px) {
        // display: block;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .contactLinks {
        a {
            color: $black;
            font-size: 40px;
            line-height: 48px;
            position: relative;
            padding-left: 55px;
            display: inline-block;

            @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                font-size: css-max(32px, css-min(calc(32px + 8 * ((100vh - 899px) / 141)), calc(32px + 8 * ((100vw - 991px) / 209))));
                line-height: css-max(38px, css-min(calc(38px + 10 * ((100vh - 899px) / 141)), calc(38px + 10 * ((100vw - 991px) / 209))));
                margin-bottom: 5px;
            }

            @media (max-width: 991px) {
                font-size: 32px;
                line-height: 38px;
                margin-bottom: 15px;
            }

            @media (max-width: 575px) {
                font-size: 24px;
                line-height: 28px;
                padding-left: 35px;
            }

            &:before {
                @extend %iconfont;
                position: absolute;
                left: 0;
                font-size: 1em;
                line-height: 1;
                color: $black;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                color: $blue;
                &:before {
                    color: $blue;
                }
            }

            &.liveChatLink {
                &:before {
                    @extend %icon-comments-o;
                }
            }

            &.mailLink {
                &:before {
                    @extend %icon-envelope-o;
                }

                .full {
                    @media (max-width: 1400px) {
                        display: none;
                    }
                }

                .short {
                    display: none;

                    @media (max-width: 1400px) {
                        display: inline;
                    }
                }
            }
        }
    }

    .socialMediaContainer {
        a {
            font-size: 40px;
            line-height: 1;
            color: $black;
            padding-right: 15px;

            &:before {
                @extend %iconfont;
            }

            &.facebook {
                &:hover, &:focus, &:active {
                    color: #1877f2;
                }

                &:before {
                    @extend %icon-facebook-square;
                }
            }

            &.twitter {
                &:hover, &:focus, &:active {
                    color: #1da1f2;
                }

                &:before {
                    @extend %icon-twitter-square;
                }
            }

            &.instagram {
                &:hover, &:focus, &:active {
                    color: #f11877;
                }

                &:before {
                    @extend %icon-instagram-square;
                }
            }
        }
    }

    .teaserLinkOuterContainer {
        margin-top: 40px;

        @media (max-width: 767px) {
            margin-left: auto;
            margin-top: 0;
        }

        @media (max-width: 400px) {
            // margin-top: 25px;
            // margin-left: 0;
        }
    }
}
