.table {
    margin-top: 45px;
    margin-bottom: 45px;

    thead {
        th {
            border-color: $gray-600;

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    tbody {
        tr {
            @media (max-width: 767px) {
                display: block;
                border-bottom: 2px solid $blue;
                margin-left: -30px;
                margin-right: -30px;
                padding-left: 30px;
                padding-right: 30px;
            }

            @media (max-width: 575px) {
                margin-left: -15px;
                margin-right: -15px;
                padding-left: 15px;
                padding-right: 15px;
            }

            &:first-child td:first-child {
                @media (max-width: 767px) {
                    border-top: 1px solid $gray-600;
                }
            }

            &:last-child {
                @media (max-width: 767px) {
                    border-bottom: none;
                }

                td:last-child {
                    @media (max-width: 767px) {
                        border-bottom: 1px solid $gray-600;
                    }
                }
            }
        }

        td {
            border-color: $gray-600;

            @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
                display: block;
            }

            &:first-child {
                @media (max-width: 767px) {
                    border-top: none;
                }
            }

            &:before {
                content: attr(data-title);
                display: none;
                font-weight: bold;

                @media (max-width: 767px) {
                    display: block;
                }
            }

            &.text-right {
                @media (max-width: 767px) {
                    text-align: left !important;
                }
            }
        }
    }
}

.rateColumn {
    br {
        @media (max-width: 767px) {
            display: none;
        }
    }

    .priceAdvantage {
        display: inline-block;
        padding: 2px;
        color: $blue;
        border: 1px solid $blue;
        background-color: #fff;

        @media (max-width: 767px) {
            margin-left: 5px;
        }
    }
}
