@font-face {
  font-family: 'Duepuntozero Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/Duepuntozero-Pro/Duepuntozero-Pro-Regular.woff2') format('woff2'),
       url('../fonts/Duepuntozero-Pro/Duepuntozero-Pro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Duepuntozero Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/Duepuntozero-Pro/Duepuntozero-Pro-Bold.woff2') format('woff2'),
       url('../fonts/Duepuntozero-Pro/Duepuntozero-Pro-Bold.woff') format('woff');
}
