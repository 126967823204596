.inlineHeadlineContainer {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    position: relative;

    .inlineHeadline {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 180px;

        @media (max-width: 1300px) {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 0;
        }

        h3 {
            @media (max-width: 1300px) {
                margin-bottom: 24px;
            }

            br {
                @media (max-width: 1300px) {
                    display: none;
                }
            }
        }
    }

    .twoColumnTextContainer {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;

        @media (max-width: 1300px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.twoColumnTextContainer {
    margin-top: 3px;
    margin-bottom: 45px;

    @media (max-width: 1300px) {
        margin-top: 0;
    }

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(24px, css-min(calc(24px + 21 * ((100vh - 899px) / 141)), calc(24px + 21 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        margin-bottom: 24px;
    }

    .twoColumnText {
        column-count: 2;
        column-gap: 55px;
        margin-bottom: 0;

        @media (max-width: 1400px) {
            column-count: auto;
        }

        @media (max-width: 1300px) {
            padding-right: 220px;
        }

        @media (max-width: 1200px) {
            padding-right: 0;
        }

        br {
            display: none;

            @media (max-width: 1400px) {
                display: block;
            }
        }
    }
}

.fullWidthBackgroundContainer {
    margin-left: var(--fullwidth-offset-left);
    margin-right: var(--fullwidth-offset-right);
    max-width: calc(100vw - 64px);

    @media (max-width: 991px), (max-height: 899px) {
        margin-left: -30px;
        margin-right: -30px;
        max-width: 100vw;
    }

    @media (max-width: 575px) {
        margin-left: -15px;
        margin-right: -15px;
    }

    &.bg-primary {
        // Creates 15 pixel white space between slides
        background: linear-gradient(to bottom, $blue calc(50% - 8px), #fff calc(50% - 7px), #fff calc(50% + 7px), $blue calc(50% + 8px));
        color: #fff;

        @media (max-width: 991px) {
            background: none;
            background-color: transparent !important;
        }

        h4, .h4 {
            color: #fff;
        }

        a {
            color: #fff;
            text-decoration: underline;

            &:hover, &:focus, &:active {
                color: #fff;
            }
        }
    }

    &:not(.init) > * {
        display: none;
    }
}

.pageTitleRight .fullWidthBackgroundContainer {
    padding-right: var(--fullwidth-content-padding-right);
    padding-left: 66px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        padding-left: css-max(30px, css-min(calc(30px + 36 * ((100vh - 899px) / 141)), calc(30px + 36 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.servicesOuterContainer {

    .swiper-slide {
        height: auto;

        @media (max-width: 991px) {
            order: 1 !important;
        }

        &:nth-child(2n) {
            margin-top: 7px !important;

            @media (max-width: 991px) {
                margin-top: 14px !important;
            }
        }

        &:nth-child(2n+1) {
            margin-bottom: 7px !important;

            @media (max-width: 991px) {
                margin-bottom: 0 !important;
                margin-top: 14px !important;
            }
        }
    }

    .serviceContainer {
        padding-top: 60px;
        padding-bottom: 60px;
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            padding-top: css-max(30px, css-min(calc(30px + 30 * ((100vh - 899px) / 141)), calc(30px + 30 * ((100vw - 991px) / 209))));
            padding-bottom: css-max(30px, css-min(calc(30px + 30 * ((100vh - 899px) / 141)), calc(30px + 30 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px), (max-height: 899px) {
            padding-top: 30px;
            padding-bottom: 60px;
            padding-left: 30px;
            padding-right: 30px;
            background-color: $blue;
            box-shadow: 1px 0px $blue;
            // min-height: 0 !important;
        }

        @media (max-width: 575px) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .service {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            align-items: center;
            position: relative;

            .serviceName {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;

                @media (max-width: 1400px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    position: static;
                }

                h4 {
                    border-bottom: none;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    padding-left: 330px;
                    position: relative;

                    @media (max-width: 1600px) {
                        padding-left: calc(200px + 130 * ((100vw - 1400px) / 200));
                    }

                    @media (max-width: 1400px) {
                        padding-left: 200px;
                        position: static;
                        margin-bottom: 15px;
                    }

                    @media (max-width: 575px) {
                        padding-left: 108px;
                    }

                    @media (max-width: 420px) {
                        padding-left: 0;
                    }

                    .index {
                        font-family: "hothouse", sans-serif;
                        font-weight: bold;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 126px;
                        line-height: 158px;

                        @media (max-width: 1600px) {
                            font-size: calc(72px + 54 * ((100vw - 1400px) / 200));
                            line-height: calc(84px + 72 * ((100vw - 1400px) / 200));
                        }

                        @media (max-width: 1400px) {
                            font-size: 72px;
                            line-height: 84px;
                            left: 16px;
                        }

                        @media (max-width: 575px) {
                            font-size: 44px;
                            line-height: 56px;
                            top: -9px;
                            transform: none;
                        }

                        @media (max-width: 420px) {
                            position: relative;
                            top: 0;
                            left: 0;
                            margin-bottom: 15px;
                            font-size: 40px;
                            line-height: 1;
                        }
                    }

                    br {
                        @media (max-width: 1400px) {
                            display: none;
                        }
                    }
                }
            }

            .serviceDescription {
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;

                @media (max-width: 1400px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                p {
                    @media (max-width: 1400px) {
                        padding-left: 200px;
                    }

                    @media (max-width: 575px) {
                        padding-left: 108px;
                    }

                    @media (max-width: 420px) {
                        padding-left: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
