.swiper-pagination {
    display: flex;
    counter-reset: pagination-count;
    z-index: 20;

    .swiper-pagination-bullet {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        text-align: center;
        font-size: 14px;
        line-height: 38px;
        font-family: "hothouse", sans-serif;
        display: block;
        border: 1px solid transparent;
        color: $blue;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            width: css-max(28px, css-min(calc(28px + 10 * ((100vh - 899px) / 141)), calc(28px + 10 * ((100vw - 991px) / 209))));
            height: css-max(28px, css-min(calc(28px + 10 * ((100vh - 899px) / 141)), calc(28px + 10 * ((100vw - 991px) / 209))));
            line-height: css-max(28px, css-min(calc(28px + 10 * ((100vh - 899px) / 141)), calc(28px + 10 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px), (max-height: 899px) {
            width: 28px;
            height: 28px;
            line-height: 28px;
        }

        &.swiper-pagination-bullet-active {
            border-color: $blue;
        }

        + .swiper-pagination-bullet {
            margin-left: 30px;

            @media (max-width: 991px), (max-height: 899px) {
                margin-left: 15px;
            }
        }

        // &:before {
        //     content: counter(pagination-count);
        //     counter-increment: pagination-count;
        //     width: 38px;
        //     height: 38px;
        //     border-radius: 50%;
        //     text-align: center;
        //     font-size: 14px;
        //     line-height: 38px;
        //     font-family: "hothouse", sans-serif;
        //     display: block;
        //     border: 1px solid transparent;
        //     color: $blue;
        //
        //     @media (max-width: 991px), (max-height: 899px) {
        //         width: 28px;
        //         height: 28px;
        //         line-height: 28px;
        //     }
        // }
        //
        // &.swiper-pagination-bullet-active:before {
        //     border-color: $blue;
        // }
    }
}

.swiper-pagination-sections {
    position: absolute;
    background-color: $sand;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-right: 15px;
    bottom: 0;
    left: 66px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        padding-top: css-max(15px, css-min(calc(15px + 15 * ((100vh - 899px) / 141)), calc(15px + 15 * ((100vw - 991px) / 209))));
        padding-bottom: css-max(15px, css-min(calc(15px + 15 * ((100vh - 899px) / 141)), calc(15px + 15 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        position: relative;
        left: 0;
        margin-left: 30px;
        margin-right: 30px;
        padding-top: 15px;
        padding-right: 0;
        padding-bottom: 30px;
        background-color: transparent;
        transform: none !important;
    }

    @media (max-width: 575px) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.swiper-pagination-services {
    float: right;
    padding-bottom: 15px;

    @media (max-width: 991px), (max-height: 899px) {
        float: none;
        background-color: $blue;
        display: flex;
        justify-content: flex-end;
        padding-right: 30px;
    }

    @media (max-width: 991px) {
        display: none;
    }

    @media (max-width: 575px) {
        padding-right: 15px;
    }

    .swiper-pagination-bullet {
        color: #fff;
        width: 28px;
        height: 28px;
        line-height: 28px;

        &.swiper-pagination-bullet-active {
            border-color: #fff;
        }
    }
}

.swiper-navigation {
    // display: none;
    display: block;
    position: absolute;
    text-align: right;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    @media (max-width: 991px) {
        display: block;
    }

    .swiper-navigation-inner {
        padding-bottom: 15px;

        @media (max-width: 991px), (max-height: 899px) {
            padding-right: 30px;

        }

        @media (max-width: 575px) {
            padding-right: 15px;
        }
    }

    .swiper-button-prev, .swiper-button-next {
        color: #fff;
        display: inline-block;
        font-size: 25px;
        line-height: 1;

        &.swiper-button-disabled {
            visibility: hidden;
        }
    }

    .swiper-button-prev {
        margin-right: 8px;

        &:hover, &:focus, &:active {
            animation: backwards-arrow-hover 600ms linear;
        }

        &:before {
            @extend %iconfont;
            @extend %icon-arrow-left;
        }
    }

    .swiper-button-next {
        margin-left: 8px;

        &:hover, &:focus, &:active {
            animation: forwards-arrow-hover 600ms linear;
        }

        &:before {
            @extend %iconfont;
            @extend %icon-arrow-right;
        }
    }
}

.swiper-wrapper {
    // transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1) !important;
    transition-timing-function: cubic-bezier(0.85, 0, 0.28, 1.18) !important;

    @media (max-width: 991px) {
        transition-timing-function: linear !important;
    }
}
