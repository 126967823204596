h1, .h1 {
    font-family: "Duepuntozero Pro", sans-serif;
    font-size: 250px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 400;
    text-align: right;
    // letter-spacing: 5px;
    // margin-top: 45px;
    margin-top: 25px;
    margin-bottom: 45px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;

    // @media (max-width: 1920px) {
    //     font-size: calc(134px + 30 * ((100vw - 1600px) / 320));
    //     letter-spacing: calc(2px + 3 * ((100vw - 1600px) / 320));
    // }
    //
    // @media (max-width: 1600px) {
    //     font-size: calc(90px + 44 * ((100vw - 1200px) / 400));
    //     letter-spacing: calc(0px + 2 * ((100vw - 1200px) / 400));
    // }
    //
    // @media (max-width: 1200px), (max-width: 1200px) and (min-height: 900px) and (max-height: 1040px) {
    //     // font-size: css-max(80px, css-min(calc(80px + 10 * ((100vh - 899px) / 141)), calc(80px + 10 * ((100vw - 991px) / 209))));
    //     letter-spacing: 0px;
    // }
    //
    // @media (max-width: 991px) {
    //     font-size: 80px;
    // }
    //
    // @media (max-width: 767px) {
    //     font-size: 56px;
    //     text-align: left;
    // }
    //
    // @media (max-width: 575px) {
    //     font-size: 32px;
    //     line-height: 36px;
    // }

    .topline {
        font-weight: 400;
        font-size: 92px;
        line-height: 1;
        // margin-bottom: 45px;
        // display: block;

        // @media (max-width: 1920px) {
        //     font-size: calc(40px + 5 * ((100vw - 1600px) / 320));
        //     margin-bottom: calc(40px + 5 * ((100vw - 1600px) / 320));
        // }
        //
        // @media (max-width: 1600px) {
        //     font-size: calc(25px + 15 * ((100vw - 1200px) / 400));
        //     margin-bottom: calc(30px + 10 * ((100vw - 1200px) / 400));
        // }
        //
        // @media (max-width: 1200px), (max-width: 1200px) and (min-height: 900px) and (max-height: 1040px) {
        //     // font-size: css-max(22px, css-min(calc(22px + 3 * ((100vh - 899px) / 141)), calc(22px + 3 * ((100vw - 991px) / 209))));
        //     // margin-bottom: css-max(25px, css-min(calc(25px + 5 * ((100vh - 899px) / 141)), calc(25px + 5 * ((100vw - 991px) / 209))));
        // }
        //
        // @media (max-width: 991px) {
        //     font-size: 22px;
        //     margin-bottom: 25px;
        // }
        //
        // @media (max-width: 767px) {
        //     font-size: 18px;
        //     line-height: 26px;
        // }
        //
        // @media (max-width: 575px) {
        //     // font-size: 18px;
        //     // line-height: 26px;
        // }

        // @media (max-width: 575px) {
        //     font-size: 80px;
        //     text-transform: none;
        // }
        //
        // tspan {
        //     @media (max-width: 575px) {
        //         text-transform: uppercase;
        //     }
        // }
    }

    .subline {
        font-weight: 400;
        font-size: 160px;
        line-height: 1;
        // margin-top: 45px;
        // display: block;

        // @media (max-width: 1920px) {
        //     font-size: calc(70px + 10 * ((100vw - 1600px) / 320));
        //     margin-top: calc(40px + 5 * ((100vw - 1600px) / 320));
        // }
        //
        // @media (max-width: 1600px) {
        //     font-size: calc(50px + 20 * ((100vw - 1200px) / 400));
        //     margin-top: calc(30px + 10 * ((100vw - 1200px) / 400));
        // }
        //
        // @media (max-width: 1200px), (max-width: 1200px) and (min-height: 900px) and (max-height: 1040px) {
        //     // font-size: css-max(42px, css-min(calc(42px + 8 * ((100vh - 899px) / 141)), calc(42px + 8 * ((100vw - 991px) / 209))));
        //     // margin-top: css-max(25px, css-min(calc(25px + 5 * ((100vh - 899px) / 141)), calc(25px + 5 * ((100vw - 991px) / 209))));
        // }
        //
        // @media (max-width: 991px) {
        //     font-size: 42px;
        //     margin-top: 25px;
        // }
        //
        // @media (max-width: 767px) {
        //     font-size: 32px;
        //     text-align: left;
        // }
        //
        // @media (max-width: 575px) {
        //     font-size: 22px;
        //     line-height: 36px;
        // }

        // @media (max-width: 575px) {
        //     font-size: 90px;
        // }
    }
}

h2, .h2 {
    font-family: "Duepuntozero Pro", sans-serif;
    color: $blue;
    font-size: 192px;
    line-height: 154px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;

    @media (max-height: 1120px) {
        font-size: 150px;
        line-height: 124px;
    }

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(106px, css-min(calc(106px + 44 * ((100vh - 899px) / 141)), calc(106px + 44 * ((100vw - 991px) / 209))));
        line-height: css-max(88px, css-min(calc(88px + 36 * ((100vh - 899px) / 141)), calc(88px + 36 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px), (max-height: 899px) {
        margin-bottom: 40px;
    }

    @media (max-width: 991px) {
        font-size: 106px;
        line-height: 88px;
    }

    @media (max-width: 575px) {
        font-size: 70px;
        line-height: 58px;
    }

    @media (max-width: 480px) {
        font-size: 60px;
        line-height: 50px;
    }
}

h3, .h3 {
    font-family: "Duepuntozero Pro", sans-serif;
    text-transform: uppercase;
    font-size: 56px;
    line-height: 1em;
    // line-height: 46px;
    margin-bottom: 0;
    font-weight: 400;

    @media (max-width: 1600px) {
        font-size: 44px;
        // line-height: 36px;
    }

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(36, css-min(calc(36 + 8 * ((100vh - 899px) / 141)), calc(36 + 8 * ((100vw - 991px) / 209))));
        // line-height: css-max(30, css-min(calc(30 + 6 * ((100vh - 899px) / 141)), calc(30 + 6 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        font-size: 36px;
        // line-height: 30px;
    }

    @media (max-width: 575px) {
        font-size: 28px;
        // line-height: 24px;
    }

    + h4, + .h4 {
        margin-top: 22px;
    }
}

h4, .h4 {
    font-family: "Duepuntozero Pro", sans-serif;
    text-transform: uppercase;
    color: $red;
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 14px;
    margin-bottom: 0;
    font-weight: 400;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(32px, css-min(calc(32px + 8 * ((100vh - 899px) / 141)), calc(32px + 8 * ((100vw - 991px) / 209))));
        line-height: css-max(38px, css-min(calc(38px + 10 * ((100vh - 899px) / 141)), calc(38px + 10 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        font-size: 32px;
        line-height: 38px;
        padding-bottom: 20px;
    }

    @media (max-width: 575px) {
        font-size: 24px;
        line-height: 28px;
    }

    &.text-right {
        @media (max-width: 767px) {
            text-align: left !important;
        }
    }
}

h4 {
    + .hr {
        @media (max-width: 767px) {
            display: none;
        }
    }
}

h5, .h5 {
    text-transform: uppercase;
    margin-bottom: 24px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(15px, css-min(calc(15px + 5 * ((100vh - 899px) / 141)), calc(15px + 5 * ((100vw - 991px) / 209))));
        margin-bottom: css-max(15px, css-min(calc(15px + 9 * ((100vh - 899px) / 141)), calc(15px + 9 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        font-size: 15px;
        margin-bottom: 15px;
    }
}

p {
    margin-bottom: 24px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(15px, css-min(calc(15px + 9 * ((100vh - 899px) / 141)), calc(15px + 9 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        margin-bottom: 15px;
    }

    &.h5 {
        text-transform: none;
    }

    &.quote {
        color: $blue;
        font-family: "Duepuntozero Pro", sans-serif;
        text-transform: uppercase;
        font-size: 14px;
    }
}

a[href^='tel:'] {
    color: $black;

    &:hover {
        color: $blue;
    }
}

.lead {
    font-size: 25px;
    line-height: 48px;
    text-transform: uppercase;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(20px, css-min(calc(20px + 5 * ((100vh - 899px) / 141)), calc(20px + 5 * ((100vw - 991px) / 209))));
        line-height: css-max(26px, css-min(calc(26px + 22 * ((100vh - 899px) / 141)), calc(26px + 22 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        font-size: 20px;
        line-height: 26px;
    }

    @media (max-width: 575px) {
        font-size: 15px;
        line-height: 20px;
    }

    &.small, .small {
        font-size: 18px;
        line-height: 30px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            font-size: css-max(15px, css-min(calc(15px + 3 * ((100vh - 899px) / 141)), calc(15px + 3 * ((100vw - 991px) / 209))));
            line-height: css-max(20px, css-min(calc(20px + 10 * ((100vh - 899px) / 141)), calc(20px + 10 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    &.big, .big {
        font-family: "Duepuntozero Pro", sans-serif;
        font-weight: 400;
        font-size: 40px;
    }
}

.leadContainer {
    padding-top: 15px;
    padding-bottom: 15px;

    .lead {
        margin-bottom: 0;
    }

    h3, .h3 {
        text-align: right;
        margin-bottom: 45px;
        margin-top: 45px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            margin-bottom: css-max(25px, css-min(calc(25px + 20 * ((100vh - 899px) / 141)), calc(25px + 20 * ((100vw - 991px) / 209))));
            margin-top: css-max(25px, css-min(calc(25px + 20 * ((100vh - 899px) / 141)), calc(25px + 20 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            margin-bottom: 25px;
            margin-top: 25px;
        }

        @media (max-width: 767px) {
            text-align: left;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    + .hr {
        margin-bottom: 28px;

        @media (max-width: 991px) {
            margin-bottom: 20px;
        }
    }
}

#home-slide .leadContainer {
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 0;
    border-bottom: none;

    + .hr {
        @media (max-width: 991px) {
            margin-bottom: 40px;
        }

        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
}

.blockquote {
    margin-bottom: 24px;
    font-size: 15px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(15px, css-min(calc(15px + 9 * ((100vh - 899px) / 141)), calc(15px + 9 * ((100vw - 991px) / 209))));
        font-size: 14px
    }

    @media (max-width: 991px), (max-height: 899px) {
        font-size: 15px;
    }

    @media (max-width: 991px) {
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 12px;
        font-style: italic;
    }

    .blockquote-footer {
        font-size: 1.0em;
        color: $black;
    }
}

ul {
    padding-left: 18px;
    margin-bottom: 24px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(15px, css-min(calc(15px + 9 * ((100vh - 899px) / 141)), calc(15px + 9 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        margin-bottom: 15px;
    }

    &.lead {
        padding-left: 0;
        list-style: none;
        line-height: 32px;

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            line-height: css-max(26px, css-min(calc(26px + 6 * ((100vh - 899px) / 141)), calc(26px + 6 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            line-height: 26px;
        }

        @media (max-width: 575px) {
            line-height: 20px;
        }

        li {
            padding-top: 8px;
            padding-bottom: 8px;

            @media (max-width: 991px) {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}
