.swipeMeContainer {
    @media (max-width: 991px), (max-height: 899px) {
        display: flex;
    }

    .swipeMe {
        display: none;
        position: relative;
        width: 50px;
        margin-left: auto;
        margin-right: 30px;

        @media (max-width: 991px), (max-height: 899px) {
            display: block;
        }

        @media (max-width: 575px) {
            margin-right: 15px;
        }

        .arrow {
            position: absolute;
            top: 4px;
            height: 10px;
            border-radius: 5px;
            background-color: $purple;
            animation: swipe-touch 5s infinite linear;

            // &:before {
            //     @extend %iconfont;
            //     @extend %icon-arrow-left;
            //     color: #aaa;
            //     font-size: 20px;
            //     line-height: 1;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            // }
        }

        .hand {
            &:before {
                @extend %iconfont;
                @extend %icon-one-finger;
                color: #aaa;
                font-size: 50px;
                line-height: 1;
                position: absolute;
                top: 0;
                left: 0;
                animation: swipe 5s infinite linear;
                transform-origin: bottom;
            }
        }
    }
}
