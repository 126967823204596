#imprintModal, #privacyModal, #supportModal {
    opacity: 1 !important;
    clip-path: circle(0px at 100% 100%);
    transition: clip-path 1000ms cubic-bezier(0, 0, 0.2, 1);

    @media (max-width: 991px), (max-height: 899px) {
        clip-path: circle(0px at 0% 100%);
    }

    &.show {
        transition: clip-path 1000ms linear;
        clip-path: circle(300vmax at 100% 100%);

        @media (max-width: 991px), (max-height: 899px) {
            clip-path: circle(300vmax at 0% 100%);
        }
    }

    &.fade {
        .modal-dialog {
            transform: none;
        }
    }

    .modal-dialog {
        margin: 0;
        max-width: none;

        &.modal-dialog-scrollable {
            @media (max-width: 991px), (max-height: 899px) {
                max-height: none;
            }

            .mainContentContainer {
                max-height: calc(100vh - 140px);
                overflow-y: auto;

                @media (max-width: 991px), (max-height: 899px) {
                    max-height: none;
                    overflow: visible !important;
                    width: 100% !important;
                }

                .jspContainer {
                    @media (max-width: 991px), (max-height: 899px) {
                        overflow: visible;
                        width: 100% !important;
                        height: auto !important;
                    }

                    .jspPane {
                        @media (max-width: 991px), (max-height: 899px) {
                            position: static;
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }

    h4 + .hr + p {
        margin-top: 24px;
    }

    .modal-content {
        border: none;
        border-radius: 0;
        background-color: $sand;

        @media (max-width: 991px), (max-height: 899px) {
            padding-bottom: 50px;
        }
    }

    .modal-body {
        padding: 0;
    }

    .modalCloseContainer {
        position: absolute;
        background-color: $sand;
        padding-bottom: 18px;
        padding-top: 18px;
        padding-right: 15px;
        bottom: 0;
        left: 66px;

        @media (max-width: 1200px), (max-height: 1040px) and (min-height: 900px) {
            left: css-max(30px, css-min(calc(30px + 36 * ((100vh - 899px) / 141)), calc(30px + 36 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px), (max-height: 899px) {
            left: 30px;
        }

        @media (max-width: 575px) {
            left: 15px;
        }
    }
}

#cookieConsentModal {
    &.show {
        ~ .modal-backdrop.show {
            display: block !important;

            ~ .modal-backdrop.show {
                display: none !important;
            }
        }

        ~ .metaColumnContainer {
            z-index: 3;
        }

        ~ #imprintModal, ~ #privacyModal {
            &.show {
                ~ .metaColumnContainer {
                    z-index: 1060;
                }
            }
        }
    }

    &.fade {
        transition: none;

        .modal-dialog {
            transition: none;
        }
    }

    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 900px;
        display: flex;
        min-height: 100vh;
        max-height: -webkit-fill-available;
        align-items: center;

        @media (max-width: 991px) {
            max-width: none;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            align-items: normal;
            max-height: none;
        }
    }

    .modal-content {
        border: none;
        border-radius: 0;
        background-color: $sand;
        color: $black;
        font-size: 14px;
    }

    .modal-body {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;

        @media (max-width: 575px) {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

    .h4 {
        color: $black;

        @media (max-width: 575px) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    p {
        a {
            color: $blue;
        }
    }

    h5 {
        margin-bottom: 0;
        font-family: "Duepuntozero Pro", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }

    .detailsBtnContainer {
        margin-bottom: 0;

        a {
            padding-left: 30px;
            position: relative;

            &:before {
                @extend %iconfont;
                @extend %icon-chevron-bottom-narrow;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 18px;
                transform: rotate(180deg);
                transition: transform linear 250ms;
            }

            &.collapsed {
                &:before {
                    transform: rotate(0deg);
                }
            }
        }
    }

    #cookieConsentDetailsCollapse {
        *:first-child {
            margin-top: 24px;
        }

        *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }

    .dp--cookie-check {
        margin-top: 55px;

        @media (max-width: 480px) {
            margin-top: 30px;
        }
    }

    .cc-compliance {
        margin-top: 41px;
        text-align: right;

        @media (max-width: 480px) {
            margin-top: 10px;
        }

        .btn-sm {
            font-size: 15px;
            line-height: 20px;
            color: #fff;
            border-radius: 0;
            padding: 15px;

            @media (max-width: 767px) {
                display: block;
            }

            &.btn-outline-primary {
                color: $blue;
                background-color: #fff;
            }

            &.btn-primary {
                background-color: $blue;
                margin-left: 30px;

                @media (max-width: 767px) {
                    margin-left: 0;
                    margin-top: 30px;
                }

                @media (max-width: 575px) {
                    margin-top: 15px;
                }
            }
        }
    }
}

.modal-backdrop {
    background-color: $black;

    &.show {
        opacity: 0.5;
        display: none !important;
    }
}
