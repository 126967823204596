.jobsContainer {
    margin-top: 35px;

    .jobContainer {
        padding-top: 15px;
        padding-bottom: 15px;

        .job {
            display: flex;
            align-items: center;

            .jobTitle {
                color: $black;
                position: relative;

                // @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
                //     font-size: css-max(22px, css-min(calc(22px + 8 * ((100vh - 899px) / 141)), calc(22px + 8 * ((100vw - 991px) / 209))));
                // }
                //
                // @media (max-width: 991px) {
                //     font-size: 25px;
                //     line-height: 30px;
                // }
                //
                // @media (max-width: 767px) {
                //     font-family: "Inter", sans-serif;
                // }
                //
                // @media (max-width: 575px) {
                //     font-size: 20px;
                //     line-height: 26px;
                // }

                br {
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
            }

            .content-link {
                margin-left: auto;
                padding-left: 30px;

                &:before {
                     @media (max-width: 767px) {
                         right: 18px;
                     }
                }

                .linkText {
                    display: inline-block;
                }
            }

            &:hover, &:focus, &:active {
                .jobTitle {
                    color: $blue;
                }
            }
        }
    }
}

.jobsDetailContainer {
    .jobContactContainer {
        display: flex;
        flex-direction: column;
        
        @media (max-width: 767px) {
            flex-direction: row;
        }

        p.lead {
            @media (max-width: 767px) {
                width: 58.33333%;
            }
        }

        .teaserLinkOuterContainer {
            margin-top: auto;
            margin-bottom: auto;

            @media (max-width: 767px) {
                width: 41.66667%;
            }

            .teaserLinkContainer {
                @media (max-width: 767px) {
                    position: relative;
                    float: right;
                }
            }
        }
    }
    .jobDescriptionContainer {
        .jobDescription {
            @media (max-width: 767px) {
                margin-bottom: 20px;
                border-bottom: 1px solid $gray-600;
                padding-bottom: 20px;
            }

            p:last-child {
                @media (max-width: 767px) {
                    margin-bottom: 0;
                }
            }

            .content-link {
                display: none;

                @media (max-width: 400px) {
                    display: inline-block;
                }
            }
        }
    }

    .backlinkContainer {
        margin-top: auto;

        &.right {
            margin-left: auto;

            @media (max-width: 767px) {
                margin-left: 0;
            }
        }
    }

    .jobsPagination {
        display: flex;
        margin-top: 10px;
        
        @media (max-width: 767px) {
            margin-top: 24px;
        }

        a {
            &.next {
                margin-left: auto;

                @media (max-width: 400px) {
                    display: none;
                }
            }
        }
    }

    .next {
        // @media (max-width: 400px) {
        //     flex: 0 0 100%;
        //     max-width: 100%;
        //     order: 3;
        // }
    }

    .prev {
        @media (max-width: 400px) {
            flex: 0 0 100%;
            max-width: 100%;
            order: 4;
        }
    }
}

#content-jobs-assistant,
#content-jobs-web-developer,
#content-jobs-project-management-webercloud-china {
    .jobContactContainer .lead {
        font-size: 20px;
        line-height: 26px;

        @media (max-width: 575px) {
            font-size: 15px;
            line-height: 20px;
        }
    }
} 