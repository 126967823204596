.scrollMeContainer {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;

    @media (max-width: 991px), (max-height: 899px) {
        display: none;
    }

    .scrollMe {
        text-align: center;
        display: block;

        span {
            display: block;
            line-height: 1;
            font-size: 40px;
            animation: color-change 4s infinite linear;
            animation-fill-mode: both;

            &:after {
                @extend %iconfont;
                @extend %icon-chevron-bottom-narrow;
            }

            &:nth-child(1) {
                animation-delay: 5s;
            }

            &:nth-child(2) {
                animation-delay: 5.25s;
            }

            &:nth-child(3) {
                animation-delay: 5.5s;
            }

            &:nth-child(4) {
                animation-delay: 5.75s;
            }

            + span {
                margin-top: -0.75em;
            }
        }
    }
}
