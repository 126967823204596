$blue:    #273583 !default;
$red:     #eb3223 !default;
$purple:  #bcbcf1 !default;
$gray-600: #707070 !default;
$sand: #fff !default;
$black:    #000 !default;

$secondary:     $red !default;


$font-family-base: 'Inter', sans-serif;

$body-color: $black !default;