.form-group {
    margin-bottom: 35px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(30px, css-min(calc(30px + 5 * ((100vh - 899px) / 141)), calc(30px + 5 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        margin-bottom: 30px;
    }

    @media (max-width: 575px) {
        margin-bottom: 20px;
    }

    .col-form-label {
        font-size: 25px;
        line-height: 1.0;
        text-transform: uppercase;
        text-align: right;
        padding-top: 18px;
        padding-bottom: 12px;

        @media (max-width: 1400px) {
            font-size: 20px;
        }

        @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
            font-size: css-max(16px, css-min(calc(16px + 4 * ((100vh - 899px) / 141)), calc(16px + 4 * ((100vw - 991px) / 209))));
        }

        @media (max-width: 991px) {
            font-size: 18px;
            text-align: left;
            padding-bottom: 0;
        }

        @media (max-width: 575px) {
            font-size: 15px;
        }

        &.req:after {
            content: ' *';
            white-space: nowrap;
        }
    }

    .form-control, .custom-select {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $gray-600;
        border-radius: 0;
        color: $black;
        padding-left: 0;
        height: 50px;
        padding-top: 20px;
        padding-bottom: 12px;

        &:focus {
            box-shadow: none;
        }
    }

    .form-control {
        padding-right: 0;
    }

    .custom-select {
        text-overflow: ellipsis;
        padding-top: 16px;
        padding-bottom: 10px;
        background-position-y: 22px;
    }

    textarea {
        resize: none;
    }

    .custom-file {
        height: 50px;

        @media (max-width: 991px) {
            border-bottom: 1px solid $gray-600;
        }

        .custom-file-input {
            height: 50px;
        }

        .custom-file-label {
            background-color: transparent;
            border: none;
            color: $black;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
            padding-bottom: 12px;
            margin-bottom: 0;
            height: 50px;
            line-height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:after {
                display: none;
            }
        }
    }

    &.fancyLabelContainer {
        position: relative;

        @media (max-width: 991px) {
            display: flex;
            flex-direction: column;
        }

        .col-form-label {
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
            transition: font-size 250ms linear, transform 250ms linear;
            top: 18px;
            position: absolute;

            @media (max-width: 991px) {
                order: 1;
                position: relative;
                top: 0;
                padding-top: 18px;
                padding-bottom: 0;
            }
        }

        .form-control {
            @media (max-width: 991px) {
                order: 2;
            }
        }

        textarea {
            transition: height 250ms linear, padding-bottom 250ms linear;
            padding-top: 0;
            padding-bottom: 0;
            border-top: 20px solid transparent;
        }

        .parsley-errors-list {
            left: 0;
            transform: none;
            top: 18px;

            @media (max-width: 991px) {
                top: auto;
                bottom: 10px;
            }
        }

        &.focused {
            .col-form-label {
                @media (min-width: 992px) {
                    font-size: 12px;
                    transform: translateY(-18px);
                }
            }

            textarea {
                height: 130px;
                padding-bottom: 12px;

                @media (max-width: 991px) {
                    height: 165px;
                }

                @media (max-width: 767px) {
                    height: 105px;
                }
            }
        }

        .parsley-error ~ .col-form-label {
            @media (min-width: 992px) {
                font-size: 12px;
                transform: translateY(-18px);
            }
        }
    }
}

.form-hint {
    margin-top: -10px;
    margin-bottom: 12px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-bottom: css-max(7.5px, css-min(calc(7.5px + 4.5 * ((100vh - 899px) / 141)), calc(7.5px + 4.5 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        text-align: left !important;
        margin-bottom: 7.5px;
    }
}

.custom-checkbox {
    padding-left: 0;
    margin-right: 55px;
    min-height: 26px;
    margin-bottom: 24px;

    .custom-control-label {
        font-family: "Duepuntozero Pro", sans-serif;
        font-size: 24px;
        line-height: 1;
        text-transform: uppercase;
        padding-left: 40px;
        color: $blue;

        @media (max-width: 991px) {
            font-size: 20px;
        }

        &:before {
            width: 26px;
            height: 26px;
            border-radius: 0;
            border: 1px solid $blue;
            background-color: #fff;
            left: 0;
            top: -3px;
        }

        &:after {
            font-size: 14px;
            line-height: 1;
            width: 26px;
            height: 26px;
            top: 4px;
            left: 4px;
            color: $blue;
        }
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &:before {
                    background-color: #fff;
                }

                &:after {
                    @extend %iconfont;
                    @extend %icon-check;
                    background: none;
                }
            }
        }

        &:disabled, &:disabled:checked {
            ~ .custom-control-label {
                color: $purple;

                &:before {
                    background-color: $purple;
                }
            }
        }
    }
}

.btn-link {
    font-family: "Duepuntozero Pro", sans-serif;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1;
    border-radius: 0;
    transition: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $blue;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        font-size: css-max(32px, css-min(calc(32px + 8 * ((100vh - 899px) / 141)), calc(32px + 8 * ((100vw - 991px) / 209))));
        line-height: css-max(38px, css-min(calc(38px + 10 * ((100vh - 899px) / 141)), calc(38px + 10 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        font-size: 32px;
        line-height: 38px;
    }

    @media (max-width: 575px) {
        font-size: 24px;
        line-height: 28px;
    }

    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $blue;
    }
}

.submitButtonContainer {
    margin-top: 70px;

    @media (max-width: 1200px), (min-height: 900px) and (max-height: 1040px) {
        margin-top: css-max(60px, css-min(calc(60px + 10 * ((100vh - 899px) / 141)), calc(60px + 10 * ((100vw - 991px) / 209))));
    }

    @media (max-width: 991px) {
        margin-top: 60px;
    }

    @media (max-width: 575px) {
        margin-top: 40px;
    }

    .backlinkContainer {
        @media (max-width: 480px) {
            flex: 0 0 100%;
            max-width: 100%;
            order: 3;
            margin-top: 20px !important;
        }
    }

    .submitButton {
        display: flex;

        @media (max-width: 480px) {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .btn {
            @media (max-width: 991px) {
                margin-left: auto;
            }
        }
    }
}

.form-text {
    margin-top: 0;
    align-self: center;
}

.formCompletedText {
    color: $blue;

    @media (max-width: 991px) {
        margin-top: 0 !important;
    }
}

.form-sent {
    display: none;
}

.form-uploading {
    flex-grow: 1;
    display: none;

    .form-uploading-label {
        margin-bottom: 8px;
        text-align: left;

        @media (max-width: 991px) {
            margin-top: 15px;
        }
    }

    .form-uploading-error-label {
        display: none;
        color: $red;
        text-align: left;
        margin-bottom: 0;
    }
}

.parsley-errors-list {
    list-style: none;
    display: none;
    color: $red;
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: 0;
    transform: translateY(-50%);
    pointer-events: none;


    &.filled {
        display: block;
    }

    li {
        list-style: none;
    }
}

.errorLabel, .filesizeErrorLabel {
    display: none;
}

.parsley-error {
    ~ .defaultLabel {
        display: none;
    }

    ~ .errorLabel {
        display: block;
        color: $red !important;
    }
}

.filesize-error {
    ~ .defaultLabel {
        display: none;
    }

    ~ .errorLabel {
        display: none;
    }

    ~ .filesizeErrorLabel {
        display: block;
        color: $red !important;
    }
}

#applicationForm {
    @media (max-width: 767px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

#application_sweets {
    position: absolute;
    top: -9999px;
    left: -9999px;
    opacity: 0;
}