.slideInFromBottom {
    &:not(.animation) {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        display: block;

        .animation {
            display: block;
        }
    }


    .animation, &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateY(100%);
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateY(0%);
            transition-duration: 500ms;
        }
    }
}

.slideInFromTop {
    &:not(.animation) {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        display: block;

        .animation {
            display: block;
        }
    }

    .animation, &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateY(-100%);
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateY(0%);
            transition-duration: 500ms;
        }
    }
}

.slideInFromRight {
    &:not(.animation) {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        display: block;

        .animation {
            display: block;
        }
    }

    .animation, &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateX(100%);
            transition-duration: 50ms;

            &.metaColumnContainer {
                @media (max-width: 991px), (max-height: 899px) {
                    transform: translateY(-100%);
                }
            }
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateX(0%);
            transition-duration: 500ms;

            &.metaColumnContainer {
                @media (max-width: 991px), (max-height: 899px) {
                    transform: translateY(0%);
                }
            }
        }
    }
}

.slideInFromLeft {
    &:not(.animation) {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        display: block;

        .animation {
            display: block;
        }
    }

    .animation, &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateX(-100%);
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateX(0%);
            transition-duration: 500ms;
        }
    }
}

.rollInFromLeft {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateX(-25%) rotate(-45deg);
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateX(0%) rotate(0deg);
            transition-duration: 500ms;
        }
    }
}

.rollInFromRight {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transition: transform ease-out;
            transform: translateX(25%) rotate(45deg);
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: translateX(0%) rotate(0deg);
            transition-duration: 500ms;
        }
    }
}

.fadeIn {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            opacity: 0;
            transition: opacity linear;
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            opacity: 1;
            transition-duration: 500ms;
        }
    }
}

.growIn {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            transform: scale(0, 1);
            transition: transform ease-out;
            transition-duration: 50ms;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: scale(1, 1);
            transition-duration: 500ms;
        }
    }

    &.fromRight {
        transform-origin: right;
    }

    &.fromLeft {
        transform-origin: left;
    }

    &.fromLeft-sm {
        @media (max-width: 767px) {
            transform-origin: left;
        }
    }
}

.rotateClockwise {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            display: inline-block;
            transform: rotate(-90deg);
            transition: transform linear;
            transition-duration: 50ms;
            transform-origin: right;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: rotate(0deg);
            transition-duration: 500ms;
        }
    }
}

.rotateCounterClockwise {
    &.animation {
        .pageContainer.swiper-slide-active &, &.global, .pageContainer:not(.animation-init) & {
            display: inline-block;
            transform: rotate(90deg);
            transition: transform linear;
            transition-duration: 50ms;
            transform-origin: bottom;
        }

        .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
            transform: rotate(0deg);
            transition-duration: 500ms;
        }
    }
}

@for $i from 0 through 20 {
    .delay-#{$i} {
        .animation, &.animation {
            transition-delay: 250ms;

            .pageContainer.swiper-slide-active.animation-ready &, .page-animation-ready &.global {
                transition-delay: #{$i * 150}ms;
            }
        }
    }
}
