a {
    &:hover, &:focus, &:active {
        text-decoration: none;
        color: $blue;
    }
}

*:focus {
    outline: none !important;
}

.hyphenate {
    -webkit-hyphens: none;
    hyphens: none;

    @media (max-width: 1600px) {
        -webkit-hyphens: manual;
        -webkit-hyphens: auto;
        hyphens: manual;
        hyphens: auto;
    }

    &.hyphenate-xs {
        hyphens: none;
        -webkit-hyphens: none;

        @media (max-width: 575px) {
            -webkit-hyphens: manual;
            -webkit-hyphens: auto;
            hyphens: manual;
            hyphens: auto;
        }
    }
}

.cite {
    text-transform: uppercase;
}

.content-link {
    position: relative;
    padding-right: 45px;
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Duepuntozero Pro", sans-serif;
    line-height: 1;
    padding-top: 18px;
    padding-bottom: 18px;
    display: block;

    &:before {
        @extend %iconfont;
        @extend %icon-arrow-right;
        position: absolute;
        right: 5px;
        top: 18px;
        font-size: 25px;
        transition: right 500ms ease;
    }

    &:after {
        content: '';
        position: absolute;
        width: 60px;
        height: 60px;
        border: 2px solid $purple;
        border-radius: 50%;
        top: 0;
        right: 0;
    }

    &.back {
        padding-right: 0;
        padding-left: 45px;

        &:before {
            @extend %icon-arrow-left;
            right: auto;
            left: 5px;
            transition: left 500ms ease;
        }

        &:after {
            right: auto;
            left: 0;
        }
    }

    .linkText {
        white-space: nowrap;
    }
}

a {
    .content-link {
        &:hover, &:focus, &:active {
            .waterfall-hover {
                .first {
                    span {
                        transform: translateY(-100%);
                        transition-duration: 400ms;

                        @media (max-width: 991px) {
                            transform: none !important;
                        }

                        @for $i from 0 through 50 {
                            &:nth-of-type(#{$i}) {
                                transition-delay: 0.02s * $i;
                            }
                        }
                    }
                }

                .second {
                    span {
                        transform: translateY(-100%);
                        transition-duration: 400ms;

                        @for $i from 0 through 50 {
                            &:nth-of-type(#{$i}) {
                                transition-delay: 0.03s * $i + 0.05;
                            }
                        }
                    }
                }

                &.vertical {
                    .first, .second {
                        span {
                            transform: translateX(100%);
                        }
                    }
                }
            }
        }

        .linkText {
            @media (max-width: 767px) {
                font-size: 0px;
            }
        }
    }

    .waterfall-hover {
        overflow: hidden;
        position: relative;
        // height: 20px;
        // clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50% );
        display: inline-block;
        vertical-align: top;

        .first {
            display: block;
            white-space: nowrap;

            @media (max-width: 991px) {
                display: none;
            }

            span {
                display: inline-block;
                transition: transform 0ms cubic-bezier(0.65, 0, 0.21, 1);
                // transition: transform 400ms cubic-bezier(0.65, 0, 0.21, 1.47);

                @for $i from 0 through 50 {
                    &:nth-of-type(#{$i}) {
                        // transition-delay: 0.03s * $i + 0.05;
                        transition-delay: 0s;
                    }
                }
            }
        }

        .second {
            display: block;
            position: absolute;
            white-space: nowrap;

            @media (max-width: 991px) {
                display: none;
            }

            span {
                display: inline-block;
                transition: transform 0ms cubic-bezier(0.65, 0, 0.21, 1);
                // transition: transform 400ms cubic-bezier(0.65, 0, 0.21, 1.47);

                @for $i from 0 through 50 {
                    &:nth-of-type(#{$i}) {
                        // transition-delay: 0.02s * $i;
                        transition-delay: 0s;
                    }
                }
            }
        }

        .mobile {
            display: none;

            @media (max-width: 991px) {
                display: block;
            }
        }
    }

    &:hover, &:focus, &:active {
        .content-link:before {
            // right: 10px;
            animation: forwards-arrow-hover 600ms linear;
        }

        .content-link.back:before {
            // left: 10px;
            animation: backwards-arrow-hover 600ms linear;
        }

        // .waterfall-hover:not(.linkText) {
        .waterfall-hover {
            .first {
                span {
                    transform: translateY(-100%);
                    transition-duration: 400ms;

                    @media (max-width: 991px) {
                        transform: none !important;
                    }

                    @for $i from 0 through 50 {
                        &:nth-of-type(#{$i}) {
                            transition-delay: 0.02s * $i;
                        }
                    }
                }
            }

            .second {
                span {
                    transform: translateY(-100%);
                    transition-duration: 400ms;

                    @for $i from 0 through 50 {
                        &:nth-of-type(#{$i}) {
                            transition-delay: 0.03s * $i + 0.05;
                        }
                    }
                }
            }

            &.vertical {
                .first, .second {
                    span {
                        transform: translateX(100%);
                    }
                }
            }
        }
    }
}

a.content-link {
    &:hover, &:focus, &:active {
        &:before {
            // right: 10px;
            animation: forwards-arrow-hover 600ms linear;
        }

        &.back:before {
            // left: 10px;
            animation: backwards-arrow-hover 600ms linear;
        }

        .waterfall-hover {
            .first {
                span {
                    transform: translateY(-100%);
                    transition-duration: 400ms;

                    @media (max-width: 991px) {
                        transform: none !important;
                    }

                    @for $i from 0 through 50 {
                        &:nth-of-type(#{$i}) {
                            transition-delay: 0.02s * $i;
                        }
                    }
                }
            }

            .second {
                span {
                    transform: translateY(-100%);
                    transition-duration: 400ms;

                    @for $i from 0 through 50 {
                        &:nth-of-type(#{$i}) {
                            transition-delay: 0.03s * $i + 0.05;
                        }
                    }
                }
            }

            &.vertical {
                .first, .second {
                    span {
                        transform: translateX(100%);
                    }
                }
            }
        }
    }
}

.external-link {
    font-family: "Duepuntozero Pro", sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1;
    position: relative;
    padding-right: 40px;

    &:after {
        @extend %iconfont;
        @extend %icon-external;
        position: absolute;
        right: 0;
        top: -4px;
        font-size: 24px;
    }
}

.marqueeContainer {
    // position: absolute;
    overflow: hidden;
    position: relative;

    .marquee {
        white-space: nowrap;
    }

    &.init {
        // overflow: hidden;
        // position: relative;

        .marquee {
            animation: text-marquee var(--animationTime) infinite linear;
        }
    }
}

.no-uppercase {
    text-transform: none;
}

.hr {
    border-bottom: 1px solid $gray-600;
}

.progress {
    background-color: $purple;
    border-radius: 0;

    .progress-bar {
        background-color: $blue;
    }
}

@keyframes text-marquee {
    from { transform: translate3d(0, 0, 0); }
    to { transform: translate3d(-50%, 0, 0); }
}

@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
}

// @keyframes color-change {
//     0% { color: $purple; }
//     1% { color: $blue; }
//     4% { color: $blue; }
//     5% { color: $purple; }
//     100% { color: $purple; }
// }

@keyframes color-change {
    0% { color: #aaa; }
    1% { color: $black; }
    6% { color: $black; }
    7% { color: #aaa; }
    100% { color: #aaa; }
}

@keyframes swipe {
    0% { opacity: 0; transform: rotate(15deg); }
    60% { opacity: 0; transform: rotate(15deg); }
    70% { opacity: 1; transform: rotate(15deg); }
    90% { opacity: 1; transform: rotate(-15deg); }
    100% { opacity: 0; transform: rotate(-15deg); }
}

@keyframes swipe-touch {
    0% { opacity: 0; width: 10px; right: 13px; }
    60% { opacity: 0; width: 10px; right: 13px; }
    70% { opacity: 0; width: 10px; right: 13px; }
    71% { opacity: 0.5; width: 10px; right: 13px; }
    80% { opacity: 0.5; width: 20px; right: 13px; }
    89% { opacity: 0.5; width: 10px; right: 33px; }
    90% { opacity: 0; width: 10px; right: 33px; }
    100% { opacity: 0; width: 10px; right: 33px; }
}

@keyframes forwards-arrow-hover {
    0% { transform: translateX(0px); }
    50% { transform: translateX(-7px); }
    100% { transform: translateX(0px); }
}

@keyframes backwards-arrow-hover {
    0% { transform: translateX(0px); }
    50% { transform: translateX(7px); }
    100% { transform: translateX(0px); }
}
