.customCursorContainer {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 9999;
    overflow: hidden;

    .customCursor {
        position: absolute;
        left: 0;
        top: 0;
        will-change: transform;
        transform: translate3d(-100px, -100px, 0);
        padding: 50px;
        margin-left: -50px;
        margin-top: -50px;

        .customCursorOuter {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $sand;
            background-color: $blue;
            transform: translate(-50%, -50%);
            transition: width 500ms ease, height 500ms ease, background-color 500ms ease;

            .customCursorInner {
                border-radius: 50%;
                width: 100%;
                height: 100%;
                border: 1px solid $blue;
            }
        }
    }

    &.state-hover {
        .customCursorOuter {
            width: 75px;
            height: 75px;
            background-color: transparent;
        }
    }
}

.mobile .customCursorContainer {
    display: none;
}

@media (any-hover: hover) {
    * {
        cursor: none !important;
    }
}

@media (any-hover: none) {
    .customCursorContainer {
        display: none;
    }
}
